import {Image} from "@chakra-ui/react";
import * as React from "react";
import {ethers} from "ethers";
import {ERC20Token} from "../../helpers/Assets";
import {ChainConfig} from "../../config/config";

const baseUri = "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains"

export function TokenLogo(props: {token: ERC20Token, h?: any, w?: any}){
    const chainConfig = ChainConfig[props.token.chainId]

    let logoUri;
    if (props.token.logo){
        logoUri = props.token.logo
    }else if (props.token.logoURI){
        logoUri = props.token.logoURI
    }else if (chainConfig.assets.trustWalletChainSlug){
        logoUri = `${baseUri}/${chainConfig.assets.trustWalletChainSlug}/assets/${ethers.utils.getAddress(props.token.address)}/logo.png`;
    }else{
        logoUri = "/images/tokenPlaceholder.png";
    }

    return (
        <Image src={logoUri} fallbackSrc='/images/tokenPlaceholder.png' maxH={props.h} maxW={props.w} />
    )
}