import {ethers} from "ethers";
import {LockerCreatedEvent, LockerFactory} from "../contracts/LockerFactory";
import {ContractReceipt} from "@ethersproject/contracts";
import {getGnosisSafeUrl, getGnosisSingleton, isGnosisSupported} from "./GnosisHelper";
import {LockerImplementation} from "../types/LockerImplementation";
import {fromUnixTime} from "date-fns";
import {getContract, getFactory} from "./DeploymentsHelper";
import {LockerFactory__factory} from "../contracts";
import { Contract, Provider } from 'ethers-multicall';
import {setMulticallAddress} from "ethers-multicall/dist/provider";
import {getChainMeta} from "../hooks/useChainMeta";

async function fetchLocker(chainId: number, id: string | number): Promise<ILocker>{
    const factory = getFactory(chainId);

    let address, locker;
    // Check if we have the ID or the address of the locker, and fetch accordingly
    if (typeof id === "number"){
        [address, locker] = await factory.getLockerById(id);
    }else{
        address = id;
        locker = await factory.lockers(id)
    }

    let actualId, owner;
    const unlocked = locker.unlockedBy !== "0x0000000000000000000000000000000000000000";
    if(unlocked){
        actualId = id;
        owner = locker.unlockedBy;
    }else{
        actualId = locker.latestId.toNumber();
        owner = await factory.ownerOf(locker.latestId);
    }

    return {
        id: locker.latestId.toNumber(),
        chainId: chainId,
        address: address,
        owner: owner,
        unlocked: unlocked,
        lockedAt: fromUnixTime(locker.createdAt.toNumber()),
        unlockAt: fromUnixTime(locker.unlockAt.toNumber()),
    }
}

function parseLocker(chainId: number, address: string, locker: LockerFactory.LockInfoStructOutput): ILocker{
    let owner = undefined;

    const unlocked = locker.unlockedBy !== "0x0000000000000000000000000000000000000000";
    if (unlocked){
        owner = locker.unlockedBy
    }

    return {
        id: locker.latestId.toNumber(),
        chainId: chainId,
        address: address,
        owner: owner,
        unlocked: unlocked,
        lockedAt: fromUnixTime(locker.createdAt.toNumber()),
        unlockAt: fromUnixTime(locker.unlockAt.toNumber()),
    }
}

async function fetchLockerImplementation(locker: ILocker): Promise<LockerImplementation | undefined>{
    const factory = getFactory(locker.chainId);

    // To get the implementation we have to read from the storage slot shown below (specified by EIP1967)
    const implementationBytes = await factory.provider.getStorageAt(
        locker.address,
        "0x360894a13ba1a3210667c828492db98dca3e2076cc3735a920a3ca505d382bbc"
    )

    // Slightly hacky way to convert the storage bytes32 to an address (the storage bytes are 0 padded to the left)
    const implementationAddress = ethers.utils.getAddress(`0x${implementationBytes.slice(implementationBytes.length - 40)}`)
    const gnosisSingletonImplementationAddress = getGnosisSingleton(locker.chainId)

    if(implementationAddress === gnosisSingletonImplementationAddress && isGnosisSupported(locker.chainId)){
        // Implementation is a gnosis singleton
        return {
            name: "Gnosis Safe",
            address: implementationAddress,
            chainId: locker.chainId,
            uri: getGnosisSafeUrl(locker.chainId, locker.address)
        }
    }else if(implementationAddress === getContract(locker.chainId, "SimpleWithdrawal")){
        return {
            name: "withdrawal contract",
            address: implementationAddress,
            chainId: locker.chainId,
            uri: `/locker/${locker.chainId}/${locker.address}/withdraw`
        }
    }

    // TODO: check for our locker implementation address
    // TODO: check for our (todo) ProxyAssetManager implementation address

    return undefined
}

async function fetchUserLockers(user: string, chainIds: number[]){
    for (let i = 0; i < chainIds.length; i++){
        const chainMeta = getChainMeta(chainIds[i])
        if (chainMeta === undefined){
            continue
        }
        setMulticallAddress(chainMeta.chainId, chainMeta.multicall2Address ? chainMeta.multicall2Address : chainMeta.multicallAddress)
    }

    const multicallPromises: Promise<any>[] = [];
    for (let i = 0; i < chainIds.length; i++) {
        const factory = getFactory(chainIds[i]);

        const multiCallProvider = new Provider(factory.provider, chainIds[i]);
        const multiCallFactory = new Contract(factory.address, LockerFactory__factory.abi)

        const ownsCount = await factory.balanceOf(user)

        let calls = [];
        for (let j = 0; j < ownsCount.toNumber(); j++){
            calls.push(multiCallFactory.tokenOfOwnerByIndex(user, j));
        }

        multicallPromises.push(
            multiCallProvider.all(calls)
        );
    }

    const lockerIdMulticalls = await Promise.all(multicallPromises)

    const lockerFetchMulticallPromises: Promise<any>[] = [];
    for (let i = 0; i < lockerIdMulticalls.length; i++) {
        const factory = getFactory(chainIds[i]);

        const multiCallProvider = new Provider(factory.provider, chainIds[i]);
        const multiCallFactory = new Contract(factory.address, LockerFactory__factory.abi)

        let calls = [];
        for (let j = 0; j < lockerIdMulticalls[i].length; j++) {

            const lockerId = lockerIdMulticalls[i][j];
            calls.push(multiCallFactory.getLockerById(lockerId));
        }

        lockerFetchMulticallPromises.push(
            multiCallProvider.all(calls)
        );
    }


    const lockersMulticalls = await Promise.all(lockerFetchMulticallPromises)
    let lockers: ILocker[] = [];
    for (let i = 0; i < lockersMulticalls.length; i++){
        for (let j = 0; j < lockersMulticalls[i].length; j++){
            lockers.push(parseLocker(chainIds[i], lockersMulticalls[i][j][0], lockersMulticalls[i][j][1]))
        }
    }

    // Sort the lockers by most recent
    lockers.sort((a, b) => {
        return b.lockedAt.getTime() - a.lockedAt.getTime()
    })

    // Dedupe same addresses
    lockers = Object.values<ILocker>(lockers.reduce((acc,cur)=>Object.assign(acc,{[cur.address]:cur}),{}))

    return lockers
}

async function fetchNewestLockers(chainIds: number[]){
    // TODO: move to somewhere so we only run this once
    for (let i = 0; i < chainIds.length; i++){
        const chainMeta = getChainMeta(chainIds[i])
        if (chainMeta === undefined){
            continue
        }
        setMulticallAddress(chainMeta.chainId, chainMeta.multicall2Address ? chainMeta.multicall2Address : chainMeta.multicallAddress)
    }

    const lastIdPromises: Promise<ethers.BigNumber>[] = []
    for (let i = 0; i < chainIds.length; i++){
        const factory = getFactory(chainIds[i]);

        lastIdPromises.push(factory.lastId())
    }
    const lastIds = await Promise.all(lastIdPromises)
    const multicallPromises: Promise<any>[] = [];
    for (let i = 0; i < chainIds.length; i++) {
        const factory = getFactory(chainIds[i]);
        const lastId = lastIds[i].toNumber()

        const multiCallProvider = new Provider(factory.provider, chainIds[i]);
        const multiCallFactory = new Contract(factory.address, LockerFactory__factory.abi)

        const fetchUntil = lastId > 15 ? (lastId - 15) : 1
        let calls = [];
        for (let j = lastId; j >= fetchUntil; j--){
            calls.push(multiCallFactory.getLockerById(j));
        }

        multicallPromises.push(
            multiCallProvider.all(calls)
        );
    }
    const lockerMulticalls = await Promise.all(multicallPromises)

    let lockers: ILocker[] = [];
    for (let i = 0; i < lockerMulticalls.length; i++){
        for (let j = 0; j < lockerMulticalls[i].length; j++){
            lockers.push(parseLocker(chainIds[i], lockerMulticalls[i][j][0], lockerMulticalls[i][j][1]))
        }
    }

    // Sort the lockers by most recent
    lockers.sort((a, b) => {
        return b.lockedAt.getTime() - a.lockedAt.getTime()
    })
    // Dedupe the lockers
    lockers = Object.values<ILocker>(lockers.reduce((acc,cur)=>Object.assign(acc,{[cur.address]:cur}),{}))

    return lockers
}

async function fetchUserNewestLocker(chainId: number, user: string) {
    const factory = getFactory(chainId);
    const balance = await factory.balanceOf(user)
    const mostRecent = await factory.tokenOfOwnerByIndex(user, balance.sub(1))

    return fetchLocker(chainId, mostRecent.toNumber())
}

// This is not working on some chains (ex. Matic), use fetchUserNewestLocker for now
function fetchLockerFromReceipt(chainId: number, receipt: ContractReceipt) {
    const factory = getFactory(chainId)
    const event = factory.interface.parseLog(receipt.logs[receipt.logs.length - 1])

    return fetchLocker(chainId, event.args[0])
}

export interface ILocker {
    id: any,
    chainId: number,
    address: string,
    owner: string | undefined,
    lockedAt: Date
    unlockAt: Date,
    unlocked: boolean

    refresh?: () => void
}

export interface LockerReference {
    id: number,
    chainId: number
}

export {fetchLocker, fetchNewestLockers, fetchUserLockers, fetchLockerFromReceipt, fetchLockerImplementation, fetchUserNewestLocker}