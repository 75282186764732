import {Text} from "@chakra-ui/react";
import * as React from "react";

export function MenuHeader(props: {label: string, children?: any}){
    return (
        <>
            <Text
                pt='15px'
                pb='3px'
                variant='menuHeader'
            >
                {props.label}
            </Text>

            {props.children}
        </>
    )
}