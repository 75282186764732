import {Chain} from "@usedapp/core/src/constants/index";

export const Hardhat: Chain = {
    chainId: 31337,
    chainName: 'Hardhat',
    isTestChain: false,
    isLocalChain: false,
    multicallAddress: '0x42ad527de7d4e9d9d011ac45b31d8551f8fe9821',
    multicall2Address: '0x5ba1e12693dc8f9c48aad8770482f4739beed696',
    getExplorerAddressLink: () => '',
    getExplorerTransactionLink: () => '',
}