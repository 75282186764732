import { createContext } from "react";
import {LockerReference} from "../helpers/Lockers";

const ownedLockerContext = createContext<{
    cachedAccount: string | undefined,
    lockersRef: LockerReference[] | undefined,
    updateLockersRef: (newLockers: LockerReference[] | undefined) => void
    updateAccount: (newAccount: string | undefined) => void
}>({
        cachedAccount: undefined,
        lockersRef: undefined,
        updateLockersRef: (newLockers: LockerReference[] | undefined) => {},
        updateAccount: (newAccount: string | undefined) => {},
    }
);

export default ownedLockerContext;