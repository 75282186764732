import {EIP721Metadata} from "../types/EIP721Metadata";
import * as URI from "uri-js";
import {ERC721Tokenish} from "./Assets";
import {BigNumber, ethers} from "ethers";

export async function resolveMetadataUri(token: ERC721Tokenish, uri: string): Promise<EIP721Metadata>{

    var result;
    // Attempt with (correct) URI format first
    try {
        const modifiedUri = uri.replaceAll('{id}',
            ethers.utils.hexZeroPad(
                BigNumber.from(token.tokenId.toString()).toHexString(),
                32
            ).substring(2).toLowerCase(),
        )

        if (modifiedUri.startsWith('ipfs://')){
            const url = `https://gateway.ipfs.io/ipfs/${modifiedUri.slice(7)}#x-ipfs-companion-no-redirect`;
            const response = await fetch(url)

            result = await response.json()
        }else if(modifiedUri.startsWith('ar://')){

        }else if (modifiedUri.startsWith('data:application/json;base64')){
            result = JSON.parse(atob(modifiedUri.slice(29)))

            // catches both http and https
        }else if (modifiedUri.startsWith('http')){
            // Assume its some sort of normal url and we can fetch it
            const response = await fetch(modifiedUri)
            result = await response.json()
        }

        if (result.image){
            result.image = resolveImageUri(result.image)
        }

    // On failure try with incorrect format
    }catch (e){
        const modifiedUri = uri.replaceAll('{id}', token.tokenId.toString())

        if (modifiedUri.startsWith('ipfs://')){
            const url = `https://gateway.ipfs.io/ipfs/${modifiedUri.slice(7)}#x-ipfs-companion-no-redirect`;
            const response = await fetch(url)

            result = await response.json()
        }else if(modifiedUri.startsWith('ar://')){

        }else if (modifiedUri.startsWith('data:application/json;base64')){
            result = JSON.parse(atob(modifiedUri.slice(29)))

            // catches both http and https
        }else if (modifiedUri.startsWith('http')){
            // Assume its some sort of normal url and we can fetch it
            const response = await fetch(modifiedUri)
            result = await response.json()
        }

        if (result.image){
            result.image = resolveImageUri(result.image)
        }
    }

    return result;
}

export function resolveImageUri(uri: string){
    if (uri.startsWith('ipfs://')){
        return `https://gateway.ipfs.io/ipfs/${uri.slice(7)}#x-ipfs-companion-no-redirect`;
    }else if(uri.startsWith('ar://')){
        return 'idkser'
    }else{
        // For anything that is not ipfs or arweave
        return uri
    }
}
