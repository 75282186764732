import {shortenIfAddress} from "@usedapp/core";
import {Box, Flex, HStack, Link, Tag} from "@chakra-ui/react";
import * as React from "react";
import {useChainMeta} from "../../hooks/useChainMeta";
import {ChainLogo} from "../ChainLogo/ChainLogo";


export function Address(props: {chainId: number, address: string}){
    const chain = useChainMeta(props.chainId)

    return (
        <Tag size='sm'>
            <Link href={chain?.getExplorerAddressLink(props.address)} isExternal>
                <HStack>
                    <ChainLogo chainId={props.chainId} h="14px" w='14px' />
                    <Box ml="4px" fontFamily={"IBM Plex Mono"}>
                        { shortenIfAddress(props.address) }
                    </Box>
                </HStack>
            </Link>
        </Tag>
    )
}