import {getSafeL2SingletonDeployment, getSafeSingletonDeployment} from "@gnosis.pm/safe-deployments";
import {GnosisSafeSingleton__factory} from "../contracts";
import {ethers} from "ethers";
import {ILocker} from "./Lockers";
import {Avalanche, Gnosis, Mainnet, Polygon, Rinkeby} from "@usedapp/core";

const GnosisVersion = "1.3.0"
// TODO: add others
const GnosisChainSlugs: {[chainId: number]: string} = {
    [Mainnet.chainId]: 'eth',
    [Rinkeby.chainId]: 'rin',
    [Avalanche.chainId]: 'Avalanche',
    [Gnosis.chainId]: 'gno',
    [Polygon.chainId]: 'MATIC',
}

export function getGnosisCalldata(
    owners: string[],
    treshold: number,
    paymentToken: string,
    paymentAmount: ethers.BigNumber,
    paymentReceiver: string
){
    return GnosisSafeSingleton__factory.createInterface().encodeFunctionData(
        "setup",
        [
            owners,         // Owners
            treshold,       // Owner treshold
            "0x0000000000000000000000000000000000000000",          // To
            "0x0000000000000000000000000000000000000000",          // Data
            "0x0000000000000000000000000000000000000000",          // Fallback handler
            paymentToken,
            paymentAmount,
            paymentReceiver,
        ]
    )
}

export function isGnosisSupported(chainId: number){
    return getGnosisSingleton(chainId) !== undefined
        &&
    GnosisChainSlugs[chainId] !== undefined
}

export function getGnosisSingleton(chainId: number){
    let singletonDeployment = undefined

    // For ETH chains we use the L1 deployment, for others we use the L2
    if (chainId === 1 || chainId === 3 || chainId === 4){
        singletonDeployment = getSafeSingletonDeployment({
            version: GnosisVersion,
            network: chainId.toString()
        })
    }else{
        singletonDeployment = getSafeL2SingletonDeployment({
            version: GnosisVersion,
            network: chainId.toString()
        })
    }

    if (singletonDeployment === undefined){
        return singletonDeployment
    }

    return singletonDeployment.networkAddresses[chainId.toString()]
}

export function getGnosisSafeUrl(chainId: number, address: string){
    return `https://gnosis-safe.io/app/${GnosisChainSlugs[chainId]}:${address}/`
}