import {
    Box,
    Button,
    Center,
    HStack,
    Image,
    LinkBox,
    LinkOverlay,
    Skeleton,
    Text
} from "@chakra-ui/react";
import {useEffect, useMemo, useState} from "react";
import {useRpc} from "../../../hooks/useRpc";
import {ERC1155__factory, ERC165__factory, ERC721Metadata__factory} from "../../../contracts";
import {resolveMetadataUri} from "../../../helpers/NFTHelper";
import {EIP721Metadata} from "../../../types/EIP721Metadata";
import {Mainnet, Rinkeby} from "@usedapp/core";
import {GoVerified} from "react-icons/go";
import {BigNumber, utils} from "ethers";
import {NftMarketplace} from "../../../types/NftMarketplace";
import {getMarketplace} from "../../../helpers/MarketplaceHelper";
import * as React from "react";
import {NFTProps} from "../NFT";
import {getContract} from "../../../helpers/DeploymentsHelper";

// Make sure addresses are checksummed
const VerifiedCollections: {[chainId: number]: {[address: string]: boolean}} = {
    [Mainnet.chainId]: {
        "0xC36442b4a4522E871399CD717aBDD847Ab11FE88": true
    },
    [Rinkeby.chainId]: {
        "0xC36442b4a4522E871399CD717aBDD847Ab11FE88": true
    }
}


export function DefaultNFT(props: NFTProps){
    const {token, size, overlay, ...other} = props
    const provider = useRpc(props.token.chainId)
    const providerLoaded = provider !== undefined;

    const [nftType, updateNftType] = useState<'none' | 'erc721' | 'erc1155'>('none')
    const [marketplace, updateMarketplace] = useState<NftMarketplace>()
    const [metadata, updateMetadata] = useState<EIP721Metadata | 'loading'>('loading')

    useEffect(() => {
        updateMarketplace(getMarketplace(props.token.chainId))
    }, [props.token.chainId])

    const isVerified = useMemo(() => {
        if(VerifiedCollections[token.chainId] && VerifiedCollections[token.chainId][utils.getAddress(token.address)]){
            return true
        }

        // Is one of our own NFTs
        return utils.getAddress(token.address) === getContract(props.token.chainId, "LockerFactory");
    }, [props.token.chainId, props.token.address])

    const name = useMemo(() => {
        if (metadata !== 'loading'){
            let name
            if (metadata.name){
                name = metadata.name
            }else if(metadata.title){
                name = metadata.title
            }else{
                name = "-"
            }

            if (name && name.length > 20){
                // TODO: fix in a clean way
                if (props.size === undefined){
                    name = name.substring(0, 12) + "..."
                }else{
                    name = name.substring(0, 17) + "..."
                }


            }

            return name
        }
    }, [metadata])


    useEffect(() => {
        if(!providerLoaded){
            return
        }

        const intro = ERC165__factory.connect(token.address, provider);
        intro.supportsInterface('0x5b5e139f').then(async (is721Metadata) => {
            if (is721Metadata){
                updateNftType('erc721')

                const nft = ERC721Metadata__factory.connect(token.address, provider)
                try {
                    await nft.tokenURI(token.tokenId).then(
                        async (uri) => {
                            const metadata = await resolveMetadataUri(token, uri);
                            return updateMetadata(metadata)
                        });
                }catch (e) {
                    return updateMetadata({
                        image: 'none'
                    })
                }

                return
            }

            const is1155Metadata = await intro.supportsInterface('0x0e89341c')
            if (is1155Metadata){
                updateNftType('erc1155')

                const nft = ERC1155__factory.connect(token.address, provider)
                try {
                    await nft.uri(token.tokenId).then(
                        async (uri) => {
                            const metadata = await resolveMetadataUri(token, uri);
                            return updateMetadata(metadata);
                        })
                }catch (e) {
                    return updateMetadata({
                        image: 'none'
                    })
                }

                return
            }

            const is1155 = await intro.supportsInterface('0xd9b67a26')
            if (is1155){
                updateNftType('erc1155')

                const nft = ERC1155__factory.connect(token.address, provider)
                const uri = await nft.uri(token.tokenId)
                const metadata = await resolveMetadataUri(token, uri);
                return updateMetadata(metadata);
            }

                updateNftType('erc721')
        },
            () => {
                return updateMetadata({
                    image: 'none'
                })
            })
    }, [token.chainId, token.address, token.tokenId, providerLoaded])


    // Fetch balance
    const [balance, updateBalance] = useState<BigNumber | undefined>(undefined)
    useEffect(() => {
        if (nftType === 'erc1155' && props.account){
            const nftInstance = ERC1155__factory.connect(props.token.address, provider)
            nftInstance.balanceOf(props.account, props.token.tokenId).then((balance) => {
                updateBalance(balance)
            })
        }
    }, [nftType, props.account])

    return (
        <LinkBox>
            <Box
                zIndex={-100}
                borderRadius={5}
                borderWidth="1px"
                bg='container'
                w={size ? size : "150px"}
                {...other}
            >
                {
                    // Only show the overlay if the NFT has been loaded
                    metadata !== 'loading' && marketplace !== undefined && overlay !== false ?
                        <Box
                            zIndex={100}
                            h="100%"
                            w="100%"
                            position="absolute"
                            opacity="0"
                            bg='#222'
                            _hover={{
                                opacity: "0.7"
                            }}
                        >
                            <LinkBox h="100%">
                                <Center h="100%">
                                        <Button size='xs'>
                                            <LinkOverlay href={marketplace.viewUri(props.token)} target='_blank'>
                                                View on {marketplace.name}
                                            </LinkOverlay>
                                        </Button>
                                </Center>
                            </LinkBox>
                        </Box>
                        : ""
                }

                <Skeleton isLoaded={metadata !== 'loading'} w={size ? size : "150px"} pb="5px">
                    {
                        metadata !== 'loading' && metadata.image !== 'none' ?
                            <Image
                                padding='5px'
                                boxSize={size ? size : "150px"}
                                objectFit='contain'
                                src={metadata !== 'loading' ? metadata.image : ""}
                            /> :
                            <Box padding='5px' boxSize={size ? size : "150px"} bg={'container'} />
                    }

                    <Center mt='5px'>
                        <HStack >
                            <Text fontWeight='bold' fontSize='xs'>
                                {
                                    metadata !== 'loading' && nftType === 'erc1155' && balance ? `x${balance.toString()} `: ""
                                }

                                {
                                    metadata === 'loading' ? "" : name
                                }

                            </Text>
                            {
                                metadata !== "loading" && isVerified ? <GoVerified color="#1d9bf0" /> : ""
                            }
                        </HStack>
                    </Center>
                </Skeleton>
            </Box>
        </LinkBox>
    )
}