import {Chain} from "@usedapp/core/src/constants/index";

export const TestMilkomeda: Chain = {
    chainId: 200101,
    chainName: 'TestMilkomeda',
    isTestChain: true,
    isLocalChain: false,
    multicallAddress: '0x5E529adEFE203380fdEf421d80D4182dd4916229',
    multicall2Address: '0xE7a2052A04F197DdE82f6e2a736404fd70bd9FA7',
    getExplorerAddressLink: (address: string) => `https://explorer-devnet-cardano-evm.c1.milkomeda.com/address/${address}`,
    getExplorerTransactionLink: (transactionHash: string) => `https://explorer-devnet-cardano-evm.c1.milkomeda.com/tx/${transactionHash}`,
}