import {useEthers} from "@usedapp/core";
import { ethers } from 'ethers'
import {config} from "../config/config";
import {Provider} from "@ethersproject/providers";
import {getASupportedChainId} from "../helpers/ChainHelper";
import {useProvider} from "wagmi";

let providers: {[chainId: number]: Provider} = {};

// Use the users RPC if possible
export const useRpc = function (overrideChainId?: number){
    //const {library, chainId } = useEthers()
    const preferredChainId = overrideChainId ? overrideChainId : undefined;
    const provider = useProvider({
        chainId: preferredChainId
    })

    // if (chainId === preferredChainId && library){
    //     return library;
    // }

    //
    // if (providers[preferredChainId] === undefined){
    //     console.log(`new provider initialized for chain ${preferredChainId}`);
    //     providers[preferredChainId] = new ethers.providers.JsonRpcProvider(
    //         config.readOnlyUrls? config.readOnlyUrls[preferredChainId] : "",
    //     )
    // }

    return provider;
}