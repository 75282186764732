/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import { Provider } from "@ethersproject/providers";
import type {
  SimpleWithdrawal,
  SimpleWithdrawalInterface,
} from "../SimpleWithdrawal";

const _abi = [
  {
    inputs: [],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    inputs: [],
    name: "InvalidParams",
    type: "error",
  },
  {
    inputs: [],
    name: "NativeAssetWithdrawalFailed",
    type: "error",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "previousOwner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "OwnershipTransferred",
    type: "event",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_owner",
        type: "address",
      },
    ],
    name: "initialize",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "owner",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "renounceOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "transferOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address payable",
        name: "to",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "nativeAmount",
        type: "uint256",
      },
      {
        internalType: "address[]",
        name: "erc20",
        type: "address[]",
      },
      {
        internalType: "uint256[]",
        name: "erc20Amounts",
        type: "uint256[]",
      },
      {
        internalType: "address[]",
        name: "erc721",
        type: "address[]",
      },
      {
        internalType: "uint256[]",
        name: "erc721Ids",
        type: "uint256[]",
      },
      {
        internalType: "address[]",
        name: "erc1155",
        type: "address[]",
      },
      {
        internalType: "uint256[]",
        name: "erc1155Ids",
        type: "uint256[]",
      },
      {
        internalType: "uint256[]",
        name: "erc1155Amounts",
        type: "uint256[]",
      },
    ],
    name: "withdraw",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
];

export class SimpleWithdrawal__factory {
  static readonly abi = _abi;
  static createInterface(): SimpleWithdrawalInterface {
    return new utils.Interface(_abi) as SimpleWithdrawalInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): SimpleWithdrawal {
    return new Contract(address, _abi, signerOrProvider) as SimpleWithdrawal;
  }
}
