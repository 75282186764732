import {
    Button,
    Stack,
    useDisclosure,
    MenuItem, Box, Text
} from "@chakra-ui/react";
import {
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
} from '@chakra-ui/react'
import {BiCaretRight, BsUnlock} from "react-icons/all";
import * as React from "react";
import {useMemo, useState} from "react";

import "flatpickr/dist/themes/dark.css";
import {ChainPicker} from "../ChainPicker/ChainPicker";
import {ILocker} from "../../helpers/Lockers";
import {ethers} from "ethers";
import {ContractReceipt} from "@ethersproject/contracts";
import {isFuture} from "date-fns";
import {useFactory} from "../../hooks/useFactory";
import {getGnosisCalldata, getGnosisSingleton, isGnosisSupported} from "../../helpers/GnosisHelper";
import {TransactionButton} from "../TransactionButton/TransactionButton";
import {BetaMessage} from "../BetaMessage/BetaMessage";
import {getContract} from "../../helpers/DeploymentsHelper";
import {SimpleWithdrawal__factory} from "../../contracts";
import {useNavigate} from "react-router-dom";
import { useSigner, useAccount } from 'wagmi'

export function UnlockModal(props: {locker: ILocker | null}) {
    const { data: signer } = useSigner();
    const { data: account } = useAccount();
    const factory = useFactory();
    const [chainState, updateChainState] = useState<boolean>();
    const { isOpen, onOpen, onClose } = useDisclosure()
    const navigate = useNavigate()

    const gnosisSupported = useMemo(() => {
        if (props.locker){
            return isGnosisSupported(props.locker?.chainId)
        }

        return false;
    }, [props.locker])

    const simpleWithdrawalSupported = useMemo(() => {
        if (props.locker){
            return getContract(props.locker.chainId, "SimpleWithdrawal") !== undefined
        }

        return false;
    }, [props.locker])

    const simpleWithdrawOnDoneHandler = (receipt: ContractReceipt) => {
        // Close the modal
        onClose()

        if (props.locker === null || props.locker.refresh === undefined){
            // Reload the page so we can display the gnosis message
            window.location.reload()
        }else{
            // Redirect user to the withdraw page
            navigate(`withdraw`)
        }
    }

    const gnosisOnDoneHandler = (receipt: ContractReceipt) => {
        // Close the modal
        onClose()

        if (props.locker === null || props.locker.refresh === undefined){
            // Reload the page so we can display the gnosis message
            window.location.reload()
        }else{
            // Reload the locker data so we can display the gnosis message
            props.locker.refresh()
        }
    }

    const onStateChange = (newReady: boolean) => {
        updateChainState(newReady)
    }

    const convertIntoSimpleWithdrawal = async () => {
        // Verify everything needed has been loaded
        if (!signer || !props.locker || await signer?.getChainId() !== props.locker.chainId || factory === undefined){
            return "Something went wrong, please refresh and try again"
        }

        const address = await signer.getAddress();
        if (address !== props.locker.owner){
            return "You are not the owner of this locker";
        }

        // Get the singleton we are going to set the locker to
        const singletonAddress = getContract(props.locker.chainId, "SimpleWithdrawal")
        if (singletonAddress === undefined){
            return "Simple Withdrawal is not supported on this chain"
        }

        const initCalldata = SimpleWithdrawal__factory.createInterface().encodeFunctionData(
        "initialize",
            [
                address // The new owner
            ]
        )

        // Unlock the locker
        return factory.connect(signer).unlock(
            props.locker.id,
            singletonAddress,
            initCalldata
        )
    }

    const convertIntoGnosis = async () => {
        // Verify everything needed has been loaded
        if (!signer || !props.locker || await signer?.getChainId() !== props.locker.chainId || factory === undefined){
            return "Something went wrong, please refresh and try again"
        }

        const address = await signer.getAddress();
        if (address !== props.locker.owner){
            return "You are not the owner of this locker";
        }

        // Get the singleton we are going to set the locker to
        const gnosisSingletonAddress = getGnosisSingleton(props.locker.chainId)
        if (gnosisSingletonAddress === undefined){
            return "Gnosis Safes are not supported by this chain"
        }

        // Build the calldata we need to perform the setup
        const calldata = getGnosisCalldata(
            [address],
            1,
            "0x0000000000000000000000000000000000000000",
            ethers.BigNumber.from(0),
            "0x0000000000000000000000000000000000000000", // TODO: set this anyway
        )

        // Unlock the locker
        return factory.connect(signer).unlock(
            props.locker.id,
            gnosisSingletonAddress,
            calldata
        )
    }

    return (
        <>
            <MenuItem isDisabled={(props.locker  === null || props.locker.unlocked || props.locker.owner !== account?.address || isFuture(props.locker.unlockAt))} onClick={onOpen} icon={<BsUnlock size='20px' />}>
                Unlock
            </MenuItem>
            <Drawer
                isOpen={isOpen}
                placement='right'
                onClose={onClose}
                size='sm'
            >
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerCloseButton />
                    <DrawerHeader borderBottomWidth='1px'>
                        Unlock locker #{props.locker?.id}
                    </DrawerHeader>
                    {
                        // This way we aren't rendering these components if they are not visible
                        isOpen ?
                            <DrawerBody>
                                <Stack spacing='24px' paddingTop='20px'>
                                    <ChainPicker onStateChange={onStateChange} forceChain={props.locker ? props.locker.chainId : 0} />
                                </Stack>

                                <Box mt='20px' p='15px' bg='container' width='100%' borderRadius={5}>
                                    <Text variant='title'>
                                        Simple Withdrawal
                                    </Text>
                                    <Text fontSize='sm'>
                                        A quick way to withdraw ERC20, ERC721 and ERC1155 assets from your locker.
                                    </Text>

                                    <TransactionButton
                                        mt='10px'
                                        size='sm'
                                        confirmations={0}

                                        onClick={convertIntoSimpleWithdrawal}
                                        onDone={simpleWithdrawOnDoneHandler}
                                        disabled={!chainState || !simpleWithdrawalSupported}
                                        rightIcon={<BiCaretRight />}
                                    >
                                        Start Simple Withdrawal
                                    </TransactionButton>
                                </Box>

                                <Box mt='20px' p='15px' bg='container' width='100%' borderRadius={5}>
                                    <Text variant='title'>
                                        Gnosis Safe
                                    </Text>
                                    <Text fontSize='sm'>
                                        Get full control over the assets in your locker, easily withdraw assets and/or perform complex transactions.
                                    </Text>

                                    <TransactionButton
                                        mt='10px'
                                        size='sm'
                                        confirmations={2}

                                        onClick={convertIntoGnosis}
                                        onDone={gnosisOnDoneHandler}
                                        disabled={!chainState || !gnosisSupported}
                                        rightIcon={<BiCaretRight />}
                                    >
                                        Convert to Gnosis Safe
                                    </TransactionButton>
                                </Box>
                            </DrawerBody>
                            : ""
                    }

                    <BetaMessage />
                    <DrawerFooter borderTopWidth='1px'>
                        <Button variant='outline' mr={3} onClick={onClose}>
                            Cancel
                        </Button>
                    </DrawerFooter>
                </DrawerContent>
            </Drawer>
        </>
    )
}