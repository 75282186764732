import {ERC721Tokenish} from "../../helpers/Assets";
import {DefaultNFT} from "./types/DefaultNFT";

// const NFThandler: {[chainId: number]: {[address: string]: (props: {token: ERC721Token}) => JSX.Element}} = {
//     // [Mainnet.chainId]: {
//     //     "0xC36442b4a4522E871399CD717aBDD847Ab11FE88": UNIv3
//     // },
//     // [Rinkeby.chainId]: {
//     //     "0xC36442b4a4522E871399CD717aBDD847Ab11FE88": UNIv3
//     // }
// }

export interface NFTProps {
    token: ERC721Tokenish
    size?: string
    overlay?: boolean
    account?: string
    // Pass any other prop and it will be put on the button
    [x:string]: any;
}

export function NFT(props: NFTProps){
   return <DefaultNFT {...props} />
}