import {Text, Button, useMediaQuery, Tooltip, LinkOverlay, LinkBox} from "@chakra-ui/react";
import {Link, matchPath, useLocation} from "react-router-dom";
import {useContext, useMemo} from "react";
import menuContext from "../../context/menuContext";

interface MenuItemProps {
    uri: string
    label: string
    expanded: boolean
    disabled?: boolean
    activePaths?: string[]
    icon?: any
    target?: string
}

export function MenuItem(props: MenuItemProps){
    const [isMobile] = useMediaQuery('(max-width: 767px)')
    const {pathname} = useLocation()
    const {setExpanded} = useContext(menuContext)

    // Check all the paths to decide if this menu item should be active or not
    const active = useMemo(() => {
        // If the activePaths are set try and match them all
        if (props.activePaths){
            for (let i = 0; i < props.activePaths.length; i++) {
                if (matchPath(props.activePaths[i], pathname)){
                    return true
                }
            }
        }

        // Try and match the Uri to the current path
        if (matchPath(props.uri, pathname)){
            return true
        }

        return false
    }, [pathname, props.activePaths])

    const width = props.expanded ? "100%" : "35px"
    const isExternal = props.target !== undefined && props.target === "_blank"

    return (
        <Tooltip label={props.label} aria-label='A tooltip' isDisabled={props.expanded}>
            <LinkBox>
                {
                    isExternal ? <LinkOverlay href={props.uri} target='_blank' /> : ""
                }
                <Button
                    as={isExternal ? undefined : Link}
                    to={{pathname: props.uri}}
                    onClick={() => isMobile && setExpanded(false)}
                    variant={active ? 'menuActive' : props.disabled === true ? 'menuDisabled' : 'menu'}
                    padding='5px 10px 5px 10px'
                    w={width}
                    h='35px'
                    justifyContent={props.expanded ? 'left' : 'center'}
                    mt='2px'
                    disabled={props.disabled}
                    _focus={{
                        outline: 'none'
                    }}
                >
                    {props.icon}
                    {
                        props.expanded ?
                            <Text ml='8px' fontSize='12px'>
                                {props.label}
                            </Text>
                        :
                            ""
                    }
                </Button>
            </LinkBox>
        </Tooltip>
    )
}