import {
    Button, ButtonGroup,
    Popover,
    PopoverArrow, PopoverBody,
    PopoverCloseButton,
    PopoverContent, PopoverFooter,
    PopoverHeader,
    PopoverTrigger, useToast
} from "@chakra-ui/react";
import * as React from "react";
import {useState} from "react";
import {ContractTransaction} from "ethers";
import {TransactionLoader} from "../TransactionLoader/TransactionLoader";
import {ContractReceipt} from "@ethersproject/contracts";
import {ButtonProps} from "@chakra-ui/button/dist/declarations/src/button";
import {on} from "cluster";

interface TransactionButtonProps {
    onClick: () => Promise<string | ContractTransaction>,
    children: any
    confirmations: number,

    // If set a popover is shown with this content and the user has to confirm their interaction
    warning?: any,

    // Gets called when the tx has been submitted and the number of confirmations has passed
    onDone?: (receipt: ContractReceipt) => void,
    disabled?: boolean,
    // Pass any other prop and it will be put on the button
    [x:string]: any;
}

export function TransactionButton(props: TransactionButtonProps & ButtonProps){
    const toast = useToast()

    const [warningState, updateWarningState] = useState<boolean>(false);
    const [inProgress, updateInProgress] = useState<boolean>();
    const [transaction, updateTransaction] = useState<ContractTransaction | null>(null);

    const {onClick, children, confirmations, warning, onDone, disabled, ...other} = props

    const onInitTransaction = async () => {
        // If we are supposed to show a warning and it is not yet shown then show it now
        if (warning !== undefined && warningState === false){
            updateInProgress(true)
            return updateWarningState(true)
        }

        updateInProgress(true)
        updateWarningState(false)

        try {
            await onClick().then((result) => {
                // Check if we got an error message
                if (typeof result ===  "string"){
                    updateInProgress(false)
                    return
                }
                updateTransaction(result)
            })
        } catch (e: any) {
            if (e.code === "UNPREDICTABLE_GAS_LIMIT"){
                toast({
                    title: `Transaction error`,
                    description: e.error.message ? e.error.message : e.reason,
                    position: 'top-right',
                    status: "error",
                    isClosable: true,
                })
            }else if (e.code === 4001){
                toast({
                    title: `Transaction cancelled`,
                    description: e.message ? e.message : "",
                    position: 'top-right',
                    status: "warning",
                    isClosable: true,
                })
            }else if(e.error && e.error.message){
                toast({
                    title: `Error`,
                    description: e.error.message,
                    position: 'top-right',
                    status: "error",
                    isClosable: true,
                })
            }else{
                toast({
                    title: `Error`,
                    description: e.message ? e.message : "",
                    position: 'top-right',
                    status: "error",
                    isClosable: true,
                })
            }

            console.log(e, e.code)
            updateInProgress(false)

        }
    }

    const onWarningClose = () => {
        updateWarningState(false)
        updateInProgress(false)
    }

    const onTransactionDone = (receipt: ContractReceipt) => {
        updateInProgress(false)

        // Perform call upwards to notify component of tx confirmation
        if (onDone){
            onDone(receipt)
        }
    }

    return (
        <>
            <TransactionLoader confirmations={confirmations} Transaction={transaction} onDone={onTransactionDone} />
            <Popover
                returnFocusOnClose={false}
                isOpen={warningState}
                onClose={onWarningClose}
                preventOverflow={true}
            >
                <PopoverTrigger>
                    <Button
                        {...other}
                        disabled={disabled || inProgress || transaction !== null}
                        onClick={onInitTransaction}
                        isLoading={inProgress || transaction !== null}
                    >
                        {children}
                    </Button>
                </PopoverTrigger>

                <PopoverContent mr='25px' fontSize='sm'>
                    <PopoverHeader fontWeight='semibold'>Confirmation</PopoverHeader>
                    <PopoverArrow />
                    <PopoverCloseButton />
                    <PopoverBody>
                        {warning}
                    </PopoverBody>
                    <PopoverFooter d='flex' justifyContent='flex-end'>
                        <ButtonGroup size='sm'>
                            <Button variant='outline' onClick={onWarningClose}>Cancel</Button>
                            <Button colorScheme='red' onClick={onInitTransaction}>Continue</Button>
                        </ButtonGroup>
                    </PopoverFooter>
                </PopoverContent>
            </Popover>
        </>
    )
}