import {Flex} from "@chakra-ui/react";
import {ProductionChains, TestnetChains} from "../../config/config";
import * as React from "react";
import {useEffect, useState} from "react";
import {getAllowTestnets, getSelectedChains, setSelectedChains} from "../../helpers/ChainHelper";
import {ChainSelectorToggle} from "./ChainSelectorToggle";

export function ChainSelector(props: {onChange?: () => void}) {
    // If we should include testnets merge the arrays
    const chains = getAllowTestnets() ? ProductionChains.concat(TestnetChains) : ProductionChains;

    const [selectedChains, updateSelectedChains] = useState<number[]>(getSelectedChains());
    const [availableChains, updateAvailableChains] = useState<number[]>(chains);

    useEffect(() => {
        let newAvailableChains: number[] = [];
        chains.forEach((chainId) => {
            if(!selectedChains.includes(chainId)){
                newAvailableChains.push(chainId);
            }
        })

        updateAvailableChains(newAvailableChains);
    }, [selectedChains])

    let deleteFromList = function (id: number) {
        let newList = selectedChains.filter(function(item) {
            return item !== id
        })

        // Update the lists
        setSelectedChains(newList);
        updateSelectedChains(newList);

        if (props.onChange){
            props.onChange();
        }
    }

    let addToList = function (id: number) {
        // we do an delete first, that way we don't have accidental duplicates
        let newList = selectedChains.filter(function(item) {
            return item !== id
        })

        newList.push(id);

        // Update the lists
        updateSelectedChains(newList);
        setSelectedChains(newList);

        if (props.onChange){
            props.onChange();
        }
    }

    return (
        <Flex
            position="sticky"
            top="0"
            zIndex="sticky"
            alignItems="center"
            flexWrap="nowrap"
            overflowX="auto"
            overflow="-moz-scrollbars-none"
            mr="10px"
            css={{
                WebkitOverflowScrolling: "touch",
                msOverflowStyle: "-ms-autohiding-scrollbar",
                "&::-webkit-scrollbar": {
                    display: "none",
                },
            }}
        >
            {
                selectedChains.map((selectedChain) => {
                    return <ChainSelectorToggle key={selectedChain + "-chain"} id={selectedChain} active={true} onClick={() => deleteFromList(selectedChain)} />
                })
            }

            {
                availableChains.map((availableChain) => {
                    return <ChainSelectorToggle key={availableChain + "-chain"} id={availableChain} active={false} onClick={() => addToList(availableChain)} />
                })
            }
            {/*<Badge>Default</Badge>*/}
            {/*<Badge colorScheme='green'>Success</Badge>*/}
            {/*<Badge colorScheme='red'>Removed</Badge>*/}
            {/*<Badge colorScheme='purple'>New</Badge>*/}
        </Flex>
    )
}