import * as React from "react";
import {Link} from 'react-router-dom'
import {
    Alert, AlertIcon,
    Box, Button, Center,
    Container, Flex,
    Heading,
    Link as cLink,
    SimpleGrid, Skeleton,
    Spacer,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr
} from "@chakra-ui/react";
import {
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
} from '@chakra-ui/react'
import {
    Menu,
    MenuButton,
    MenuList,
    MenuDivider,
} from '@chakra-ui/react'
import {useParams} from "react-router-dom";
import {FiChevronDown, GoLinkExternal, ImLock, ImUnlocked} from "react-icons/all";
import {useEffect, useMemo, useState} from "react";
import {fetchLocker, fetchLockerImplementation, ILocker} from "../../helpers/Lockers";
import {delay, ERC20Token, ERC721Token, fetchAssets, fetchNFTs} from "../../helpers/Assets";
import {Address} from "../../components/Address/Address";
import {Assets} from "../../components/Assets/Assets";
import {LockTokensModal} from "../../components/LockTokensModal/LockTokensModal";
import {LockNFTModal} from "../../components/LockNFTModal/LockNFTModal";
import {UnlockModal} from "../../components/UnlockModal/UnlockModal";
import {LockerImplementation} from "../../types/LockerImplementation";
import {lightFormat} from "date-fns";
import {PageHeading} from "../../partials/PageHeading";
import {ExtendLockModal} from "../../components/ExtendLockModal/ExtendLockModal";
import {Helmet} from "react-helmet";
import {useChainMeta} from "../../hooks/useChainMeta";
import {ethers} from "ethers";
import {AssetsAttribution} from "../../components/Assets/AssetsAttribution";

export function Locker(){
    let urlParams = useParams();

    const [locker, updateLocker] = useState<ILocker | null>(null)
    const [implementation, updateImplementation] = useState<LockerImplementation | undefined>(undefined)
    const [assets, updateAssets] = useState<ERC20Token[] | 'loading'>('loading')
    const [nfts, updateNFTs] = useState<ERC721Token[] | 'loading'>('loading')

    const chainId = parseInt(urlParams["chainSlug"] ?? "");

    const lockerId = useMemo(() => {
        if(urlParams["lockerId"]){
            if (ethers.utils.isAddress(urlParams["lockerId"])){
                return urlParams["lockerId"]
            }else{
                return parseInt(urlParams["lockerId"])
            }
        }

        return 0
    }, [chainId, urlParams["lockerId"]])


    const chain = useChainMeta(chainId);

    const refreshLocker = () => {
        fetchLocker(chainId, lockerId).then((locker) => {
            locker.refresh = refreshLocker
            updateLocker(locker)

            if (locker.unlocked){
                fetchLockerImplementation(locker).then((imp) => {
                    updateImplementation(imp)
                })
            }

            updateAssets('loading')
            fetchAssets(chainId, locker.address).then(async (tokens) => {
                updateAssets(tokens)

                // We have to delay this request so we don't get throttled by etherscan
                delay(5000).then(() => {
                    fetchNFTs(chainId, locker.address).then((nfts) => {
                        updateNFTs(nfts)
                    });
                })
            });
        })
    }

    useEffect(() => {
        updateLocker(null)
        refreshLocker()
    }, [chainId, lockerId])

    return (
        <>
            <Helmet>
                <title>{`Locker #${locker?.id} on ${chain?.chainName}`}</title>
                <meta name="description" content="About DuoCash" />
            </Helmet>

            <PageHeading>
                <Box>
                    <Breadcrumb color='#1e262f'>
                        <BreadcrumbItem>
                            <BreadcrumbLink as={Link} to='/lockers/new'>All Lockers</BreadcrumbLink>
                        </BreadcrumbItem>

                        <BreadcrumbItem>
                            <BreadcrumbLink as={Link} to='/lockers/new'>{chain?.chainName}</BreadcrumbLink>
                        </BreadcrumbItem>

                        <BreadcrumbItem>
                            <BreadcrumbLink as={Link} to={`/locker/${chainId}/${lockerId}`}>#{locker?.id}</BreadcrumbLink>
                        </BreadcrumbItem>
                    </Breadcrumb>
                    <Heading size='md'>
                        <Skeleton isLoaded={locker?.unlocked !== undefined} h="25px">
                            {
                                locker?.unlocked !== undefined ?
                                    locker?.unlocked ?
                                        `Unlocked Locker`
                                        :
                                        `Locker #${locker?.id} on ${chain?.chainName}`
                                    :
                                    ``

                            }
                        </Skeleton>
                    </Heading>
                </Box>

                <Spacer />

                <Menu>
                    <MenuButton
                        as={Button}
                        aria-label='Options'
                        variant='outline'
                    >
                        <Flex alignContent='center'>
                            <Box display={{base: 'none', md: 'inline-flex'}} mr='4px' mt='-3px'>
                                Actions
                            </Box>

                            <FiChevronDown />
                        </Flex>
                    </MenuButton>
                    <MenuList>
                        <LockTokensModal locker={locker} />
                        <LockNFTModal locker={locker} />
                        <MenuDivider />
                        <ExtendLockModal locker={locker} />
                        <UnlockModal locker={locker} />
                    </MenuList>
                </Menu>
            </PageHeading>

            <Container maxW='container.lg' mt="20px">
                {
                    implementation !== undefined ?
                        <Alert status='info' mb="20px" fontSize='sm'>
                            <Flex w='100%'>
                                <Center>
                                    <AlertIcon />
                                    <Text>
                                        This locker has been unlocked and was converted into a {implementation.name}
                                    </Text>
                                </Center>

                                <Spacer />
                                <Button size='sm' as={cLink} href={implementation.uri} isExternal>
                                    <Text mr='5px' display={{base: 'none', md: 'flex'}}>
                                        Open App
                                    </Text>
                                    <GoLinkExternal style={{marginTop: '3px'}}/>
                                </Button>
                            </Flex>


                        </Alert> : ""
                }

                <SimpleGrid columns={[1, null, 2]} spacing='10px'>
                    <Box width="100%" borderRadius={5} borderWidth="1px" bg='rgba(255, 255, 255, 0.03)'>
                        <Table variant='simple' size='sm' mt='5px'>
                            <Thead>
                                <Tr>
                                    <Th>
                                        Information
                                    </Th>
                                    <Th></Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                <Tr>
                                    <Td>
                                        Status
                                    </Td>
                                    <Td fontWeight='600'>
                                        <Skeleton isLoaded={!!locker} h='20px'>
                                            {
                                                locker !== null ? locker.unlocked ?
                                                        // When the locker was unlocked
                                                        <Flex>
                                                            <ImUnlocked color='gray' />
                                                            <Text ml='5px'>Unlocked</Text>
                                                        </Flex>:
                                                        (locker.unlockAt.getTime() - Date.now() < 0) ?
                                                            // When the locker is able to be unlocked, but not yet
                                                            <Flex>
                                                                <ImLock color='gray' />
                                                                <Text ml='5px'>Unlockable</Text>
                                                            </Flex> :
                                                            // When the locker is not unlocked and not unlockable
                                                            <Flex>
                                                                <ImLock />
                                                                <Text ml='5px'>Locked</Text>
                                                            </Flex>
                                                    : ""
                                            }
                                        </Skeleton>
                                    </Td>
                                </Tr>
                                <Tr>
                                    <Td>
                                        Locked until
                                    </Td>
                                    <Td>
                                        <Skeleton isLoaded={!!locker} h='20px'>
                                            {   locker?.unlockAt.getTime() === 0 ? "-" :
                                                lightFormat(locker !== null ? locker.unlockAt : new Date(), "yyyy-MM-dd")
                                            }
                                        </Skeleton>
                                    </Td>
                                </Tr>
                                <Tr>
                                    <Td>
                                        Locker address
                                    </Td>
                                    <Td>
                                        <Skeleton isLoaded={!!locker}>
                                            <Address chainId={chainId} address={locker?.address ?? ""} />
                                        </Skeleton>
                                    </Td>
                                </Tr>
                                <Tr>
                                    <Td>
                                        Owned by
                                    </Td>
                                    <Td>
                                        <Skeleton isLoaded={!!locker}>
                                            <Address chainId={chainId} address={locker?.owner ?? ""} />
                                        </Skeleton>
                                    </Td>
                                </Tr>
                            </Tbody>
                        </Table>
                    </Box>
                    <Assets assets={assets} nfts={nfts} address={locker?.address ?? ""} chainId={locker?.chainId ?? 1} />
                </SimpleGrid>
                <AssetsAttribution chainId={chainId} />
            </Container>
        </>

    )
}