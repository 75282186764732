import {ChainConfig} from "../config/config";

async function fetchAssets(chainId: number, account: string){
    return ChainConfig[chainId].assets.ERC20Handler(account)
}

async function fetchNFTs(chainId: number, account: string){
    return ChainConfig[chainId].assets.ERC721Handler(account)
}

interface BlockscoutTokenResponse {
    message: string,
    result: {
        balance: string,
        contractAddress: string,
        decimals: string,
        type: string,
        name: string,
        symbol: string,
    }[],
    status: string,
}

interface EtherscanERC20Response {
    message: string,
    result: {
      contractAddress: string,
      tokenDecimal: string,
      tokenName: string,
      tokenSymbol: string,
    }[],
    status: string,
}

interface ERC20Token {
    address: string,
    chainId: number,
    name: string,
    symbol: string,
    decimals: number,
    logo?: string
    logoURI?: string
}

interface ERC721Token {
    address: string,
    chainId: number,
    tokenId: string | number,
    name: string,
    symbol: string,
    decimals: number,
}

type ERC721Tokenish = ERC721Token | {address: string, chainId: number, tokenId: string | number};

async function fetchERC20AssetsFromBlockscout(chainId: number, baseUrl: string, account: string): Promise<ERC20Token[]> {
    const response = await fetch(`${baseUrl}/api?module=account&action=tokenlist&address=${account}`)
    const result: BlockscoutTokenResponse = await response.json()

    if (result.status !== "1"){
        console.log(`Blockscout API error ${result.message}`)
        return []
    }

    var tokens: ERC20Token[] = [];
    for (let i = 0; i < result.result.length; i++) {
        const item = result.result[i];

        if (item.type !== "ERC-20"){
            continue
        }

        tokens.push({
            address: item.contractAddress,
            chainId: chainId,
            name: item.name,
            symbol: item.symbol,
            decimals: parseInt(item.decimals)
        })
    }

    return tokens;
}

async function fetchERC721AssetsFromBlockscout(chainId: number, baseUrl: string, account: string): Promise<ERC721Token[]> {
    const response = await fetch(`${baseUrl}/api?module=account&action=tokenlist&address=${account}`)
    const result: BlockscoutTokenResponse = await response.json()

    if (result.status !== "1"){
        console.log(`Blockscout API error ${result.message}`)
        return []
    }

    var tokens: ERC721Token[] = [];
    for (let i = 0; i < result.result.length; i++) {
        const item = result.result[i];

        if (item.type !== "ERC-721" && item.type !== "ERC-1155"){
            continue
        }

        tokens.push({
            address: item.contractAddress,
            chainId: chainId,
            tokenId: item.balance,
            name: item.name,
            symbol: item.symbol,
            decimals: parseInt(item.decimals)
        })
    }

    return tokens;
}


async function fetchERC20AssetsFromEtherscan(chainId: number, baseUrl: string, account: string): Promise<ERC20Token[]>{
    const response = await fetch(`${baseUrl}/api?module=account&action=tokentx&address=${account}&sort=asc&apikey=YourApiKeyToken`)
    const result: EtherscanERC20Response = await response.json()

    if (result.status !== "1"){
        console.log(`Etherscan API error ${result.message}`)
        return []
    }

    // dedupe tokens
    var seen: {[key: string]: boolean} = {};
    result.result = result.result.filter(function(item) {
        return seen.hasOwnProperty(item.contractAddress) ? false : (seen[item.contractAddress] = true);
    });

    var tokens: ERC20Token[] = [];
    for (let i = 0; i < result.result.length; i++) {
        const item = result.result[i];
        tokens.push({
            address: item.contractAddress,
            chainId: chainId,
            name: item.tokenName,
            symbol: item.tokenSymbol,
            decimals: parseInt(item.tokenDecimal)
        })
    }

    return tokens
}

async function fetchERC721AssetsFromEtherscan(chainId: number, baseUrl: string, account: string): Promise<ERC721Token[]>{
    const response = await fetch(`${baseUrl}/api?module=account&action=tokennfttx&address=${account}&sort=asc&apikey=YourApiKeyToken`)
    const result = await response.json()

    if (result.status !== "1"){
        console.log(`Etherscan API error ${result.message}`)
        return []
    }

    var seen: {[key: string]: boolean} = {};
    result.result = result.result.filter(function(item: any) {
        return seen.hasOwnProperty(item.contractAddress) ? false : (seen[item.contractAddress] = true);
    });

    var tokens: ERC721Token[] = [];
    for (let i = 0; i < result.result.length; i++) {
        const item = result.result[i];
        tokens.push({
            address: item.contractAddress,
            chainId: chainId,
            tokenId: parseInt(item.tokenID),
            name: item.tokenName,
            symbol: item.tokenSymbol,
            decimals: item.tokenDecimal
        })
    }

    return tokens
}


async function fetchMockERC20s(): Promise<ERC20Token[]>{
    return [{
        chainId: 31337,
        name: "MockERC20",
        symbol: "Mock",
        decimals: 18,
        address: "0x7c2Db2724aA09bE3c609503d22dcd9dE7C7913e3",
    }]
}

async function fetchMockERC721s(): Promise<ERC721Token[]>{
    //
    return [
        {
            chainId: 31337,
            tokenId: "1",
            name: "MockERC721",
            symbol: "Mock721",
            decimals: 0,
            address: "0x4A64E894430b935D396F279331aa8694Cd59c821",
        },
        {
            chainId: 31337,
            tokenId: "2",
            name: "MockERC721",
            symbol: "Mock721",
            decimals: 0,
            address: "0x4A64E894430b935D396F279331aa8694Cd59c821",
        },
        {
            chainId: 31337,
            tokenId: "3",
            name: "MockERC721",
            symbol: "Mock721",
            decimals: 0,
            address: "0x4A64E894430b935D396F279331aa8694Cd59c821",
        },


        {
            chainId: 31337,
            tokenId: "1",
            name: "MockERC1155",
            symbol: "Mock1155",
            decimals: 0,
            address: "0x46859cA30a261E7a31a33720c4ef98EE195B910F",
        },
        {
            chainId: 31337,
            tokenId: "2",
            name: "MockERC1155",
            symbol: "Mock1155",
            decimals: 0,
            address: "0x46859cA30a261E7a31a33720c4ef98EE195B910F",
        },
        {
            chainId: 31337,
            tokenId: "3",
            name: "MockERC1155",
            symbol: "Mock1155",
            decimals: 0,
            address: "0x46859cA30a261E7a31a33720c4ef98EE195B910F",
        },
    ];
}

function delay(time: number) {
    return new Promise(resolve => setTimeout(resolve, time));
}

export {fetchAssets, fetchNFTs, delay, fetchERC20AssetsFromEtherscan, fetchMockERC20s, fetchMockERC721s, fetchERC20AssetsFromBlockscout, fetchERC721AssetsFromEtherscan, fetchERC721AssetsFromBlockscout}
export type {ERC20Token, ERC721Token, ERC721Tokenish}