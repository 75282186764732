import * as React from "react"
import {DAppProvider} from '@usedapp/core'
import {
    Box, Flex, useMediaQuery, useColorMode
} from "@chakra-ui/react"
import { TopBar } from "./partials/topbar"
import {config} from "./config/config";
import { Routes, Route } from "react-router-dom";
import {Dashboard} from "./views/Dashboard";
import {Locker} from "./views/Lockers/Locker";
import {Sidebar} from "./partials/sidebar";
import {NewLockers} from "./views/Lockers/NewLockers";
import {OwnedLockers} from "./views/Lockers/OwnedLockers";
import MenuContext from "./context/menuContext";
import {useLocalStorage} from "./hooks/useLocalStorage";
import {useEffect} from "react";
import {About} from "./views/About";
import {ComingSoon} from "./views/ComingSoon";
import {SimpleWithdrawal} from "./views/Lockers/Implementations/SimpleWithdrawal";

export const App = () => {
    const [isMobile] = useMediaQuery('(max-width: 767px)')
    const {colorMode, setColorMode} = useColorMode()

    const [expanded, setExpanded] = useLocalStorage<boolean>("menuExpanded", false)

    useEffect(() => {
        if (colorMode === 'light'){
            setColorMode('dark')
        }
    }, [colorMode])

    return (
        <DAppProvider config={config}>
            <MenuContext.Provider value={{expanded: expanded, setExpanded: setExpanded}}>
                    <Flex>
                        <Sidebar minHeight='100vh' w='100px' bg='container'/>

                        <Box w='100%' mb="30px" minHeight="calc(100vh - 30px)">
                            <TopBar />
                            <Routes>
                                <Route path="/" element={<Dashboard/>}/>
                                <Route path="/about" element={<About/>}/>
                                <Route path="/lockers/new" element={<NewLockers/>}/>
                                <Route path="/lockers/owned" element={<OwnedLockers/>}/>
                                <Route path="/locker/:chainSlug/:lockerId" element={<Locker/>}/>
                                <Route path="/locker/:chainSlug/:lockerId/withdraw" element={<SimpleWithdrawal />}/>

                                <Route path="/developers" element={<ComingSoon/>}/>
                                <Route path="/docs" element={<ComingSoon/>}/>
                                <Route path="/analytics" element={<ComingSoon/>}/>
                            </Routes>
                        </Box>
                    </Flex>
            </MenuContext.Provider>
        </DAppProvider>
    )
}
