import {
    Button,
    Stack,
    Box,
    useDisclosure,
    FormLabel,
    Input, FormControl, Checkbox, FormHelperText, Heading, Text, Center
} from "@chakra-ui/react";
import {
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
} from '@chakra-ui/react'
import {AiOutlinePlus} from "react-icons/all";
import * as React from "react";
import Flatpickr from "react-flatpickr";
import {useContext, useState} from "react";
import "flatpickr/dist/themes/dark.css";
import {useEthers} from "@usedapp/core";
import {ChainPicker} from "../ChainPicker/ChainPicker";
import {useFactory} from "../../hooks/useFactory";
import {utils} from "ethers";
import {ContractReceipt} from "@ethersproject/contracts";
import {fetchUserNewestLocker} from "../../helpers/Lockers";
import {useNavigate} from "react-router-dom";
import {getUnixTime} from "date-fns";
import {TransactionButton} from "../TransactionButton/TransactionButton";
import {getASupportedChainId} from "../../helpers/ChainHelper";
import OwnedLockersContext from "../../context/ownedLockersContext";
import {BetaMessage} from "../BetaMessage/BetaMessage";
import {useAccount, useSigner} from "wagmi";

export function NewLockerModal() {
    const { data: signer } = useSigner();
    const { data: account } = useAccount();

    const factory = useFactory();

    const {cachedAccount, updateAccount, lockersRef, updateLockersRef} = useContext(OwnedLockersContext)

    const [ready, updateReady] = useState<boolean>();
    const [unlockDate, updateUnlockDate] = useState<Date>(new Date(Date.now()));
    const { isOpen, onOpen, onClose } = useDisclosure()

    const [createLocked, updateCreateLocked] = useState<boolean>(false)
    const [owner, updateOwner] = useState<false | string>(false)

    const navigate = useNavigate()

    const createLocker = async () => {
        if(!account || !signer || factory === undefined){
            return "Something went wrong, please refresh and try again"
        }

        return factory.connect(signer).setup(
            owner === false ? await signer.getAddress() : owner,
            createLocked ? getUnixTime(unlockDate) : "0x0"
        )
    }

    const onStateChange = (newReady: boolean) => {
        updateReady(newReady)
    }

    const onDone = async (receipt: ContractReceipt) => {
        if(!account){
            navigate(`/locker/owned`)
            return
        }
        // Fetch the locker from the receipt
        const locker = await fetchUserNewestLocker(getASupportedChainId(await signer?.getChainId()), owner === false ? account.address ? account.address : "" : owner)
        // Close the modal
        onClose()
        // Invalidate the lockers cache (since there is now a new one)
        if(lockersRef !== undefined){
            updateLockersRef(undefined)
        }
        // Navigate to the correct page
        navigate(`/locker/${locker.chainId}/${locker.id}`)
    }

    return (
        <>
            <Button onClick={onOpen} leftIcon={<AiOutlinePlus />} size='sm' minW="120px" variant='outline' borderColor='containerBorder'>
                New Locker
            </Button>
            <Drawer
                isOpen={isOpen}
                placement='right'
                onClose={onClose}
                size='sm'
            >
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerCloseButton />
                    <DrawerHeader borderBottomWidth='1px'>
                        Create a new locker
                    </DrawerHeader>

                    <DrawerBody>
                        <Stack spacing='24px' paddingTop='20px'>
                            <ChainPicker onStateChange={onStateChange} />
                            <FormControl pl='10px'>
                                <Heading size='md' ml='-10px' mb='15px'>
                                    Unlock
                                </Heading>
                                <Checkbox isChecked={createLocked} onChange={() => updateCreateLocked(!createLocked)}>
                                    Set an unlock date
                                </Checkbox>
                                <FormHelperText>
                                    Tip: Set the unlock date when creating the locker, that way you save yourself a transaction
                                </FormHelperText>
                            </FormControl>

                            {
                                createLocked ?
                                    <FormControl pl='10px' m='15px 0px 0px 0px'>
                                        <FormLabel>
                                            Unlock date
                                        </FormLabel>
                                        <Box sx={{'.datepicker': {
                                                    border: '1px solid',
                                                    borderColor: 'inherit',
                                                    bg: 'inherit',
                                                    width: '100%',
                                                    height: '2.5rem',
                                                    borderRadius: '0.375rem',
                                                    px: '16px',
                                                }}}>
                                            <Flatpickr
                                                value={unlockDate}
                                                options={{
                                                    minDate: "today"
                                                }}
                                                onChange={([newUnlockDate]) => {
                                                    updateUnlockDate(newUnlockDate);
                                                }}
                                                className='datepicker'
                                            />
                                        </Box>
                                        <FormHelperText>
                                            After this date the locker becomes unlockable by the owner.
                                        </FormHelperText>
                                    </FormControl>
                                :
                                    ""
                            }

                            <FormControl pl='10px'>
                                <Heading size='md' ml='-10px' mb='15px'>
                                    Ownership
                                </Heading>
                                <Checkbox isChecked={owner !== false} onChange={() => updateOwner(owner !== false ? false : (account?.address ? account.address : ""))}>
                                    Give the ownership to someone else
                                </Checkbox>
                                <FormHelperText>
                                    By default the creator gets the ownership.
                                </FormHelperText>
                            </FormControl>

                            {
                                owner !== false ?
                                    <FormControl pl='10px'>
                                        <FormLabel>
                                            Owner of the locker
                                        </FormLabel>
                                        <Input type='text' fontSize='sm' value={owner} onChange={(newState) => updateOwner(newState.target.value)} isInvalid={!utils.isAddress(owner)} />
                                        <FormHelperText>
                                            Make sure the owner has access to this chain
                                        </FormHelperText>
                                    </FormControl>
                                    :
                                    ""
                            }

                        </Stack>
                    </DrawerBody>

                    <BetaMessage />
                    <DrawerFooter borderTopWidth='1px'>
                        <Button variant='outline' mr={3} onClick={onClose}>
                            Cancel
                        </Button>
                        <TransactionButton
                            onClick={createLocker}
                            onDone={onDone}
                            confirmations={0}
                            disabled={!ready}
                            mr={'oi'}
                        >
                            Create
                        </TransactionButton>
                    </DrawerFooter>
                </DrawerContent>
            </Drawer>
        </>
    )
}