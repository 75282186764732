import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    useDisclosure, Progress, Heading, Box, Text, Spinner, Flex, Spacer,
} from '@chakra-ui/react'
import {ContractTransaction} from "ethers";
import {useState} from "react";
import {ContractReceipt} from "@ethersproject/contracts";
import {useRpc} from "../../hooks/useRpc";
import {useInterval} from "../../hooks/useInterval";

interface TransactionLoaderProps {
    onDone?: (receipt: ContractReceipt) => void,
    confirmations: number
    Transaction: ContractTransaction | null
}

export function TransactionLoader(props: TransactionLoaderProps) {
    const { onClose } = useDisclosure()
    const [confirmations, updateConfirmations] = useState(0)
    const provider = useRpc(props.Transaction?.chainId)

    useInterval(() => {
        if(props.Transaction !== null) {
            provider.getTransactionReceipt(props.Transaction.hash).then((receipt) => {
                // Check if the tx is done or not
                if (receipt !== null && receipt.confirmations !== null && receipt.confirmations >= props.confirmations){
                    // Pass the success state upwards
                    if(props.onDone !== undefined){
                        props.onDone(receipt);
                    }
                    // tx is done, perform cleanup
                    updateConfirmations(0)
                    // Close the modal
                    onClose()
                }else if(receipt === null || receipt.confirmations === null){
                    updateConfirmations(0)
                }else{
                    // Tx is not done yet, update the number of confirmations
                    updateConfirmations(receipt.confirmations)
                }

                // Most likely the error that the tx has not been included yet
            }, (err) => {
                updateConfirmations(0)
            })
        }else{
            // Reset back to 0
            if (confirmations !== 0){
                updateConfirmations(0)
            }
        }
    }, 3000)

    return (
        <Modal isOpen={props.Transaction !== null} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalBody>
                    {
                        props.Transaction !== null ?
                            <Box pt='20px' pb='10px'>
                                <Heading size='sm' mb='20px'>
                                    Waiting on transaction...
                                </Heading>
                                <Progress
                                    width='100%'
                                    height="5px"
                                    size='sm'
                                    mb='15px'
                                    isIndeterminate={confirmations === 0}
                                    value={confirmations / (props.confirmations / 100)}
                                />
                                {
                                    confirmations !== 0 ?
                                        <Flex alignItems='center'>
                                            <Spinner color='fontAccent' size='xs' mr='15px' mt='4px' />
                                            <Text variant='support' mt='5px'>
                                                Confirmations {confirmations}/{props.confirmations}
                                            </Text>
                                            <Spacer />
                                        </Flex>

                                        :
                                        <Text variant='support' mt='5px'>Waiting for the transaction to be included in a block...</Text>
                                }
                            </Box>
                        :
                            ""
                    }
                </ModalBody>
            </ModalContent>
        </Modal>

    )
}
