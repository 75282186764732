import {ChainConfig} from "../../config/config";
import {Link, Text} from "@chakra-ui/react";

export function AssetsAttribution(props: {chainId: number}) {
    const meta = ChainConfig[props.chainId]
    const provider = meta.assets.ERC20Provider;

    return (
        <Text variant='support' fontSize='xs' ml={{base: '5px', md: '51%'}} mt='5px'>
            Asset data provided by <Link href={provider.url} isExternal>{provider.name}</Link>
        </Text>
    )
}