import {ChainConfig, config} from "../config/config";
import {Chain} from "@rainbow-me/rainbowkit/dist/components/RainbowKitProvider/RainbowKitChainContext";

function isChainSupported(chainId?: number): boolean{
    if (!chainId){
        return false
    }

    if(config.networks){
        for (let i = 0; i < config.networks?.length; i++) {
            if (chainId === config.networks[i].chainId){
                return true;
            }
        }
    }

    return false
}

function getASupportedChainId(chainId?: number): number{
    if (chainId && isChainSupported(chainId)){
        return chainId
    }

    if(config.networks){
        return config.networks[0].chainId;
    }else{
        return 1;
    }
}

function getWAGMIChainConfigs(): Chain[] {
    let chains: Chain[] = [];
    let includeTestnets = getAllowTestnets();

    if(config.networks){
        for (let i = 0; i < config.networks?.length; i++) {
            const currChain = config.networks[i];
            const advConfig = ChainConfig[currChain.chainId];

            if(!includeTestnets && (currChain.isTestChain || currChain.isLocalChain)){
                continue
            }

            chains.push({
                blockExplorers: {
                    etherscan: {
                        name: advConfig.assets.ERC20Provider.name,
                        url: advConfig.assets.ERC20Provider.url
                    },
                    default : {
                        name: advConfig.assets.ERC20Provider.name,
                        url: advConfig.assets.ERC20Provider.url
                    }
                },
                iconBackground: advConfig.theming.gradientStart,
                iconUrl: `/chains/${currChain.chainId}.png`,
                id: currChain.chainId,
                name: currChain.chainName,
                nativeCurrency: {
                    name: "Ether",
                    symbol: "ETH",
                    decimals: 18
                },
                rpcUrls: {
                    default: config.readOnlyUrls ? config.readOnlyUrls[currChain.chainId] : ""
                },
                testnet: currChain.isTestChain || currChain.isLocalChain
            })
        }
    }

    return chains
}

function getSelectedChains(): number[]{
    let storageValue = localStorage.getItem("SELECTED_CHAINS");

    if (!storageValue){
        return [1,137,56];
    }

    return JSON.parse(storageValue)
}

function setSelectedChains(chains: number[]){
    return localStorage.setItem("SELECTED_CHAINS", JSON.stringify(chains))
}

function getAllowTestnets(): boolean{
    let storageValue = localStorage.getItem("ALLOW_TESTNETS");

    if (!storageValue){
        return false;
    }

    return JSON.parse(storageValue)
}

function setAllowTestnets(include: boolean){
    return localStorage.setItem("ALLOW_TESTNETS", JSON.stringify(include))
}

export {isChainSupported, getASupportedChainId, getWAGMIChainConfigs, getSelectedChains, setSelectedChains, getAllowTestnets, setAllowTestnets}