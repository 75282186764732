import {BigNumber, ContractTransaction, ethers, utils} from "ethers";
import * as React from "react";
import {ERC721Token} from "../../helpers/Assets";
import {ILocker} from "../../helpers/Lockers";
import {NFT} from "../NFT/NFT";
import {Box, Center} from "@chakra-ui/react";
import {useEffect, useState} from "react";
import {ERC1155__factory, ERC165__factory, ERC721Metadata__factory} from "../../contracts";
import {useRpc} from "../../hooks/useRpc";
import {TransactionButton} from "../TransactionButton/TransactionButton";

export function SimpleWithdrawNFTItem(props: {nft: ERC721Token, locker: ILocker, handler: (type: string, details: {address?: string, amount?: BigNumber, id?: BigNumber | string | number } ) => Promise<string | ContractTransaction>}) {
    const provider = useRpc(props.nft.chainId)
    const [nftType, updateNftType] = useState<'none' | 'erc721' | 'erc1155'>('none')

    const [balance, updateBalance] = useState<BigNumber | undefined>()
    const [ownedByLocker, updateOwnedByLocker] = useState<boolean | undefined>()

    useEffect(() => {
        if (utils.isAddress(props.nft.address)){
            const intro = ERC165__factory.connect(props.nft.address, provider);
            intro.supportsInterface('0x5b5e139f').then(async (is721Metadata) => {
                if (is721Metadata){
                    updateNftType('erc721')

                    // Check if locker owns asset
                    const nft = ERC721Metadata__factory.connect(props.nft.address, provider)
                    const owner = await nft.ownerOf(props.nft.tokenId)
                    updateOwnedByLocker(owner.toLowerCase() === props.locker.address.toLowerCase())

                    return
                }

                const is1155Metadata = await intro.supportsInterface('0x0e89341c')
                if (is1155Metadata){
                    updateNftType('erc1155')

                    // Fetch balance
                    const nft = ERC1155__factory.connect(props.nft.address, provider)
                    const balance = await nft.balanceOf(props.locker.address, props.nft.tokenId)
                    updateBalance(balance)

                    return
                }

                const is1155 = await intro.supportsInterface('0xd9b67a26')
                if (is1155){
                    updateNftType('erc1155')

                    // Fetch balance
                    const nft = ERC1155__factory.connect(props.nft.address, provider)
                    const balance = await nft.balanceOf(props.locker.address, props.nft.tokenId)
                    updateBalance(balance)

                    return
                }

                // Idk what this even is, we assume ERC721
                updateNftType('erc721')
                return
            }, () => {
                updateNftType('none')
            });
        }else{
            updateNftType('none')
        }
    }, [props.nft.address])

    const withdraw = async () => {
        if (nftType === 'erc721'){
            return props.handler('erc721', {
                address: props.nft.address,
                id: props.nft.tokenId
            });

        }else if (nftType === 'erc1155'){
            const nft = ERC1155__factory.connect(props.nft.address, provider)
            const balance = await nft.balanceOf(props.locker.address, props.nft.tokenId)

            return props.handler('erc1155', {
                address: props.nft.address,
                id: props.nft.tokenId,
                amount: balance,
            });
        }

        return "Something went wrong..."
    }

    const updateStateDone = () => {
        if (nftType === 'erc721'){
            updateOwnedByLocker(false)
        }else if (nftType === 'erc1155'){
            updateBalance(BigNumber.from(0))
        }
    }

    return (
        <Box>
            <Center>
                <NFT token={props.nft} overlay={false} account={props.locker.address} />
            </Center>
            <Center>
                <TransactionButton
                    disabled={ nftType === 'none' ||
                        (nftType === 'erc721' && !ownedByLocker) ||
                        (nftType === 'erc1155' && balance?.isZero())}
                    onClick={withdraw}
                    onDone={updateStateDone}
                    confirmations={0}
                    size='xs'
                    mt="5px"
                >
                    Withdraw {props.nft.symbol}
                </TransactionButton>
            </Center>
        </Box>
    )
}