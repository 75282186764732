import {Text, Center, Flex, Input, Spacer, VStack} from "@chakra-ui/react";
import {useState} from "react";
import {ERC20Token} from "../../helpers/Assets";
import {TokenPicker} from "../TokenPicker/TokenPicker";
import {TokenLogo} from "../TokenLogo/TokenLogo";
import {useBalance} from "../../hooks/useBalance";
import {ethers} from "ethers";
import {ChainConfig} from "../../config/config";

interface TokenPickerOptions{
    chainId: number
    account: string

    onChange?: (newToken: ERC20Token, amount: ethers.BigNumber) => void,
}

export function TokenInput(props: TokenPickerOptions){
    const [amount, updateAmount] = useState<string>("")
    const [token, updateToken] = useState<ERC20Token | null>(null)
    const balance = useBalance(props.chainId, token !== null ? token.address : undefined, props.account)

    const onPick = (token: ERC20Token) => {
        updateToken(token)

        if (props.onChange){
            if (amount){
                props.onChange(token, ethers.utils.parseUnits(amount, token.decimals))
            }else{
                props.onChange(token, ethers.BigNumber.from(0))
            }
        }

        return true
    }

    const amountChange = (newAmount: string) => {
        if(!balance || !token){
            return
        }
        // Update the text string
        updateAmount(newAmount)
        // Pass the new value upwards
        if (props.onChange){
            const amount = ethers.utils.parseUnits(newAmount, token?.decimals)

            // If the user has entered more than they actually have we pass 0 upwards,
            // this way buttons will be disabled without having to check the balance at every step
            if (amount.lte(balance)){
                props.onChange(token, amount)
            }else{
                props.onChange(token, ethers.BigNumber.from(0))
            }
        }
    }

    const setMax = () => {
        if(!balance || !token){
            return
        }

        // Set the amount to the current balance
        amountChange(ethers.utils.formatUnits(balance, token?.decimals))
    }

    return (
        <Flex
            w='100%'
            bg='rgba(0, 0, 0, 0.15)'
            borderRadius='13px'
            mb='0px'
            px='15px'
        >
            <Input
                value={amount}
                onChange={(event: any) => {amountChange(event.target.value)}}
                pr='4.5rem'
                type={'number'}
                placeholder='0.0'
                fontSize='24px'
                variant='unstyled'
                min={0}
                isInvalid={
                    balance !== null && amount && token ? balance.lt(ethers.utils.parseUnits(amount, token?.decimals)) : false
                }
                _invalid={{
                    color: 'red'
                }}
            />

            <Spacer />
            <VStack my='10px'>
                <TokenPicker chainId={props.chainId} onPick={onPick} tokenlistUri={ChainConfig[props.chainId].assets.tokenList}>
                    <Center
                        w='120px'
                        h='40px'
                        bg='rgba(0, 0, 0, 0.15)'
                        borderRadius='13px'
                    >
                        {
                            token ? <Center>
                                <TokenLogo token={token} h='20px' w='20px'/>
                                <Text variant='title' ml='5px'>{token.symbol}</Text>
                            </Center>
                                :
                            <Text>
                                Pick a token
                            </Text>
                        }

                    </Center>
                </TokenPicker>

                <Text variant='support' cursor='pointer' onClick={setMax}>
                    Balance: { balance !== null ? ethers.utils.formatUnits(balance, token?.decimals) : "-"}
                </Text>
            </VStack>
        </Flex>
    )
}