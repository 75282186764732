import {Chain} from "@usedapp/core/src/constants/index";

export const AvaxFuji: Chain = {
    chainId: 43113,
    chainName: 'Fuji',
    isTestChain: true,
    isLocalChain: false,
    multicallAddress: '0x5E529adEFE203380fdEf421d80D4182dd4916229',
    getExplorerAddressLink: (address: string) => `https://testnet.snowtrace.io/address/${address}`,
    getExplorerTransactionLink: (transactionHash: string) => `https://testnet.snowtrace.io/tx/${transactionHash}`,
}