import {Tr, Td, Skeleton} from "@chakra-ui/react"

import * as React from "react";

export function LockerItemLoading() {
        return (
            <Tr>
                <Td>
                    <Skeleton h='20px' w='100%' />
                </Td>
                <Td>
                    <Skeleton h='20px' w='100%' />
                </Td>
                <Td display={{base: 'none', md: 'table-cell'}}>
                    <Skeleton h='20px' w='100%' />
                </Td>
                <Td display={{base: 'none', sm: 'table-cell'}}>
                    <Skeleton h='20px' w='100%' />
                </Td>
                <Td>
                    <Skeleton h='20px' w='100%' />
                </Td>
            </Tr>
        )
}
