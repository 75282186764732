import * as React from "react";
import {PageHeading} from "../partials/PageHeading";
import {
    Box,
    Center,
    Container,
    Grid,
    GridItem,
    Heading,
    Text, useMediaQuery,
    Wrap,
    WrapItem
} from "@chakra-ui/react";
import {NFT} from "../components/NFT/NFT";
import {ChainConfig, ProductionChains} from "../config/config";
import {ChainLogo} from "../components/ChainLogo/ChainLogo";

export function Dashboard(){
    const [isMobile] = useMediaQuery('(max-width: 767px)')

    const sellAsNft = (
        <GridItem colSpan={{base: 12, md: 4}} mt={isMobile ? "30px" : undefined} mb={isMobile ? "40px" : undefined}>
            <Center>
                <NFT size="200px" token={{
                    address: "0xFeB0944E45938a959eF896b1B13346d33b7EC783",
                    chainId: 1,
                    tokenId: 1,
                }} />
            </Center>
        </GridItem>

        // <GridItem colSpan={{base: 12, md: 4}} mb={isMobile ? "40px" : undefined}>
        //     <Image src='/images/looksrare_screenshot.png' />
        // </GridItem>
    )


    const networks = (
        <GridItem colSpan={{base: 12, md: 4}} mb={isMobile ? "40px" : undefined}>
            <Wrap justify='center'>
                {
                    ProductionChains.map((chainId) => {
                        const chainConfig = ChainConfig[chainId]
                        return (
                            <WrapItem>
                                <Center
                                    m="5px"
                                    borderRadius={5}
                                    height="60px"
                                    width="80px"
                                    bgGradient={`linear(to-br, ${chainConfig.theming.gradientStart}, ${chainConfig.theming.gradientEnd})`}
                                >
                                    <ChainLogo
                                        chainId={chainId}
                                        h="40px"
                                        w="40px"
                                    />
                                </Center>
                            </WrapItem>
                        )
                    })
                }
            </Wrap>
        </GridItem>
    )

    return (
        <>
            <PageHeading
                title='Welcome to DuoCash'
                subTitle="Next generation asset lockers"
            />
            <Container maxW='container.lg'>
                <Grid
                    templateRows='repeat(2, 1fr)'
                    templateColumns='repeat(12, 1fr)'
                    gap={{base: "10px", md: "60px"}}
                    rowGap={{base: "10px", md: "100px"}}

                    textAlign={{base: 'center', md: 'left'}}
                    alignItems="center"
                    pt={isMobile ? undefined : "40px"}
                >
                    <GridItem colSpan={{base: 12, md: 6}}>
                        <Heading color="fontAccent" size="md" mb="5px">
                            Support for every type of asset
                        </Heading>
                        <Text variant='support'>
                            Our lockers are unique, they support almost anything you can think of. Such as regular tokens (ERC20), NFTs (ERC721), Multi-Tokens (ERC1155), Ownership of contracts, DuoCash Lockers (Yes lockerception is possible) and even Airdrops.
                        </Text>
                    </GridItem>

                    <GridItem colSpan={{base: 12, md: 6}}  mb={isMobile ? "40px" : undefined}>
                        <Center>
                            <NFT size={ isMobile ? "170px" : "200px"} overlay={false} token={{
                                address: "0xC36442b4a4522E871399CD717aBDD847Ab11FE88",
                                chainId: 1,
                                tokenId: 71589,
                            }} />

                            <Box ml="10px">
                                <NFT size={ isMobile ? "170px" : "200px"} overlay={false} token={{
                                    address: "0x9d8fa3806d92d3d299010114c53cb3dd2c627279",
                                    chainId: 1,
                                    tokenId: "3",
                                }} />
                            </Box>
                        </Center>
                    </GridItem>

                    { isMobile ? "" : sellAsNft}


                    <GridItem colSpan={{base: 12, md: 8}}>
                        <Heading color="fontAccent" size="md" mb="5px">
                            Sell your locker as a NFT
                        </Heading>
                        <Text variant='support'>
                            When you create a new DuoCash locker you will receive an NFT. Whoever owns the NFT may unlock the locker once the timer has expired. This NFT you can send to another wallet or sell it on a marketplace.
                                <br />
                                <br />
                            Are you building a new project? Instead of burning/locking your LP tokens you can lock them in a DuoCash Locker and do a giveaway (or auction)!
                        </Text>
                    </GridItem>

                    { isMobile ? sellAsNft : "" }

                    {/*{ isMobile ? networks : "" }*/}

                    <GridItem colSpan={{base: 12, md: 8}} mt="20px" mb={isMobile ? "20px" : undefined}>
                        <Heading color="fontAccent" size="md" mb="5px">
                            Available on { ProductionChains.length } networks
                        </Heading>
                        <Text variant='support'>
                            We are looking to expand to as many chains as possible, can't find your favorite chain? Send us a message, maybe we can add it for you.
                         </Text>
                    </GridItem>

                    {networks}

                    {/*{ isMobile ? "" : networks }*/}
                </Grid>
            </Container>
        </>

    )
}