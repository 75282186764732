import {extendTheme} from '@chakra-ui/react'
import { mode } from '@chakra-ui/theme-tools'
import { theme as defaultTheme }  from "@chakra-ui/react"

// Version 2: Using functions
const theme = extendTheme({
    initialColorMode: 'dark',
    useSystemColorMode: false,

    // fonts: {
    //     heading: "Open Sans",
    // },

    classes: {
        datepicker: {
            backgroundColor: 'red'
        }
    },

    colors: {
        fontAccent: "#576574",
        accent: "rgba(251,217,49,0.05)",
        container: "rgba(255, 255, 255, 0.03)",
        containerBorder: "#2d3748",
    },

    components: {
        Alert: {
            baseStyle: {
                container: {
                    color: '#eee',
                    fontWeight: '300',
                    borderRadius: '5px',
                },
            },
        },
        Text: {
            variants: {
                menuHeader: {
                    color: "fontAccent",
                    textTransform: 'uppercase',
                    fontSize: '10px',
                    fontWeight: 400,
                },
                additional: {
                    fontSize: '12px'
                },
                support: {
                    color: "fontAccent",
                    fontSize: '14px'
                },
                regular: {
                    fontSize: '16px'
                },
                title: {
                    fontSize: '18px',
                    fontWeight: 'bold'
                }
            }
        },
        Heading: {
            baseStyle: {
                fontFamily: "IBM Plex Mono",
            }
        },
        Button: {
            variants: {
                menu: {
                    color: "#637185",
                    fontSize: '16px',
                    _hover: {
                        backgroundColor: 'container',
                        color: "#EEE",
                    }
                },
                menuActive: {
                    color: "#EEE",
                    backgroundColor: 'container',
                    fontSize: '16px',
                },
                menuDisabled: {
                    fontSize: '16px',
                    color: "#404956",
                }
            }
        }
    },

    styles: {
        global: (props: any) => ({
            body: {
                // backgroundColor: mode("#f7f1e3", undefined)(props),
                fontFamily: 'body',
                color: mode('gray.800', 'whiteAlpha.900')(props),
                lineHeight: 'base',
            },
        }),
    }
})

export default theme