import {Chain} from "@usedapp/core/src/constants/index";

export const EVMOS: Chain = {
    chainId: 9001,
    chainName: 'EVMOS',
    isTestChain: false,
    isLocalChain: false,
    multicallAddress: '0x80ed034722D8e0D9aC1F39EF69c65dfbf9b8C558',
    getExplorerAddressLink: (address: string) => `https://evm.evmos.org/address/${address}`,
    getExplorerTransactionLink: (transactionHash: string) => `https://evm.evmos.org/tx/${transactionHash}`,
}