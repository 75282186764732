import {
    Text, Flex, Input, InputGroup, InputLeftElement, LinkBox,
    Modal, ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    useDisclosure, VStack, Spacer, Center
} from "@chakra-ui/react";
import {ERC20Token} from "../../helpers/Assets";
import * as React from "react";
import {FocusableElement} from "@chakra-ui/utils";
import {AiOutlineSearch} from "react-icons/all";
import {useTokenList} from "@usedapp/core";
import {TokenLogo} from "../TokenLogo/TokenLogo";
import {useMemo, useState} from "react";

import {useToken} from "../../hooks/useToken";

interface TokenPickerOptions{
    // ChainId which the tokens have to be on
    chainId: number
    // Will be called once a token has been selected, if it returns false the token will not be picked
    onPick: (token: ERC20Token) => boolean
    children: any,
    tokenlistUri: string,
    // If filled we will show balances
    account?: string
    // This element will get the focus after picking is done
    finalRef?: React.RefObject<FocusableElement>

}

export function TokenPicker(props: TokenPickerOptions){
    const { isOpen, onOpen, onClose } = useDisclosure()
    const tokenList = useTokenList(props.tokenlistUri, props.chainId)
    const [query, updateQuery] = useState<string>("")
    const queryChange = (event: any) => updateQuery(event.target.value)

    const token = useToken(props.chainId, query)

    const close = () => {
        updateQuery("")
        onClose()
    }

    const pick = (token: ERC20Token) => {
        if(props.onPick(token)){
            close()
        }
    }

    const filteredList = useMemo(() => {
        if (!tokenList){
            return undefined;
        }

        return tokenList.tokens.filter((item) => {
            if (!query){
                return true
            }

            if (item.name.toLowerCase().includes(query.toLowerCase())){
                return true
            }

            if (item.symbol.toLowerCase().includes(query.toLowerCase())){
                return true
            }

            return false;
        })
    }, [tokenList, query])

    return (
        <>
            <LinkBox onClick={onOpen} cursor='pointer'>
                {props.children}
            </LinkBox>

            <Modal finalFocusRef={props.finalRef} isOpen={isOpen} onClose={close}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Select a token</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <InputGroup>
                            <InputLeftElement
                                pointerEvents='none'
                                children={<AiOutlineSearch />}
                            />
                            <Input onChange={queryChange} placeholder='Search name or paste address' />
                        </InputGroup>

                        {
                            filteredList ?
                                <VStack spacing='5px' mx={'-24px'} mt='10px'>
                                    { token !== null ?
                                        <Flex w='100%' h='50px' _hover={{bg: "rgba(0, 0, 0, 0.15)"}} px='24px' cursor='pointer' onClick={() => pick(token)}>
                                            <Center>
                                                <TokenLogo
                                                    token={token}
                                                    w='24px'
                                                    h='24px'
                                                />
                                            </Center>

                                            <Center ml='15px'>
                                                <VStack align='left' spacing='0px'>
                                                    <Text fontWeight={500}>{token.symbol}</Text>
                                                    <Text fontWeight={300} fontSize='12px'>{token.name}</Text>
                                                </VStack>
                                            </Center>
                                            <Spacer />
                                            <Text></Text>
                                        </Flex> : ""
                                    }
                                    {
                                        filteredList.map((token) => {
                                            return (
                                                <Flex key={token.address} w='100%' h='50px' _hover={{bg: "rgba(0, 0, 0, 0.15)"}} px='24px' cursor='pointer' onClick={() => pick(token)}>
                                                    <Center>
                                                        <TokenLogo
                                                            token={token}
                                                            w='24px'
                                                            h='24px'
                                                        />
                                                    </Center>

                                                    <Center ml='15px'>
                                                        <VStack align='left' spacing='0px'>
                                                            <Text fontWeight={500}>{token.symbol}</Text>
                                                            <Text fontWeight={300} fontSize='12px'>{token.name}</Text>
                                                        </VStack>
                                                    </Center>
                                                    <Spacer />
                                                    <Text></Text>
                                                </Flex>
                                            )
                                        })
                                    }
                                    <Flex>

                                    </Flex>
                                </VStack>
                                :
                                "Could not load tokenlist"
                        }
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}