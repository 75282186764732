import {ChainId} from "@usedapp/core/src/constants/index";
import {useMemo} from "react";
import {config} from "../config/config";

export function useChainMeta(chainId: ChainId) {
    return useMemo(() => getChainMeta(chainId), [chainId])
}

export function getChainMeta(chainId: ChainId) {
    return Object.values(
        config.networks ? config.networks : []
    ).find((chain) => chain.chainId === chainId)
}
