import {HStack, Td, Text, Tr} from "@chakra-ui/react";
import {TokenLogo} from "../TokenLogo/TokenLogo";
import {TransactionButton} from "../TransactionButton/TransactionButton";
import {BigNumber, ContractTransaction, ethers} from "ethers";
import * as React from "react";
import {ERC20Token} from "../../helpers/Assets";
import {useBalance} from "../../hooks/useBalance";
import {ILocker} from "../../helpers/Lockers";

export function SimpleWithdrawERC20Item(props: {token: ERC20Token, locker: ILocker, handler: (type: string, details: {address?: string, amount?: BigNumber, id?: BigNumber}) => Promise<string | ContractTransaction>}) {
    const balance = useBalance(props.token.chainId, props.token.address, props.locker.address)

    return (
        <Tr key={`${props.token.chainId}:${props.token.address}`}>
            <Td>
                <HStack>
                    <TokenLogo token={props.token} h={'15px'} w={'15px'} />
                    <Text ml="5px">{props.token.name}</Text>
                </HStack>
            </Td>
            <Td>ERC20</Td>
            <Td isNumeric>{balance ? ethers.utils.formatUnits(balance, props.token.decimals) : "0"}</Td>
            <Td isNumeric>
                <TransactionButton onClick={() => props.handler("erc20", {
                    address: props.token.address,
                    amount: balance ? balance : BigNumber.from(0)
                })} confirmations={0} size='xs'>
                    Withdraw
                </TransactionButton>
            </Td>
        </Tr>
    )
}