import {useTokenBalance} from "@usedapp/core";
import {useEffect, useState} from "react";
import {useRpc} from "./useRpc";
import {BigNumber, Contract} from "ethers";

const contractAbiFragment = [
    {
        name: 'balanceOf',
        type: 'function',
        inputs: [
            {
                name: '_owner',
                type: 'address',
            },
        ],
        outputs: [
            {
                name: 'balance',
                type: 'uint256',
            },
        ],
        constant: true,
        payable: false,
    },
];

export function useBalance(chainId: number, token?: string, owner?: string){
    const provider = useRpc(chainId)
    const providerLoaded = !!provider
    const [balance, updateBalance] = useState<BigNumber | null>(null)

    useEffect(() => {
        if (providerLoaded && token && owner){
            const contract = new Contract(token, contractAbiFragment, provider);
            contract.balanceOf(owner).then((balance: BigNumber) => {
                updateBalance(balance)
            });
        }
    }, [chainId, token, owner, providerLoaded])

    return balance
}