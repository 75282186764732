import {Chain} from "@usedapp/core/src/constants/index";

export const Ethereum: Chain = {
    chainId: 1,
    chainName: 'Ethereum',
    isTestChain: false,
    isLocalChain: false,
    multicallAddress: '0xeefba1e63905ef1d7acba5a8513c70307c1ce441',
    multicall2Address: '0x5ba1e12693dc8f9c48aad8770482f4739beed696',
    getExplorerAddressLink: (address: string) => `https://etherscan.io/address/${address}`,
    getExplorerTransactionLink: (transactionHash: string) => `https://etherscan.io/tx/${transactionHash}`,
}