import {Text, Tr, Td, Tag, Skeleton, LinkBox, LinkOverlay} from "@chakra-ui/react"
import {fetchLocker, ILocker, LockerReference} from "../../helpers/Lockers";
import {ImLock, ImUnlocked} from "react-icons/all";
import {ChainLogo} from "../ChainLogo/ChainLogo";
import {Address} from "../Address/Address";
import {formatDistanceToNow, isFuture} from "date-fns";
import * as React from "react";
import {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {LockerItemLoading} from "./LockerItemLoading";

export function LockerItem(props: {locker: LockerReference | ILocker}) {
    const navigate = useNavigate()
    const [locker, updateLocker] = useState<ILocker>()

    useEffect(() => {
        // Check if the prop is a loaded locker
        if ('unlockAt' in props.locker){
            // It has already been loaded
            updateLocker(props.locker)
        }else{
            // Load the locker data
            fetchLocker(props.locker.chainId, props.locker.id).then((lockerData) => {
                updateLocker(lockerData)
            })
        }
    }, [props.locker])

    // Loading
    if (!locker){
        return <LockerItemLoading />
    }

    return (
        <Link to={`/locker/${locker.chainId}/${locker.address}`} style={{display: "table-row", verticalAlign: 'middle'}}>
            <Td>
                {
                    locker.unlocked ?
                        // When the locker was unlocked
                        <ImUnlocked color='gray' /> :
                        (locker.unlockAt.getTime() - Date.now() < 0) ?
                            // When the locker is able to be unlocked, but not yet
                            <ImLock color='gray' />:
                            // When the locker is not unlocked and not unlockable
                            <ImLock />
                }
            </Td>
            <Td>
                <Tag px='4px'>
                    <ChainLogo chainId={locker.chainId} h="14px" w='14px'/>
                    <Text px='4px' fontWeight='800'>
                        #{locker.id}
                    </Text>
                </Tag>
            </Td>
            <Td display={{base: 'none', md: 'table-cell'}}>
                <Address chainId={locker.chainId} address={locker.address ?? ""} />
            </Td>
            <Td display={{base: 'none', sm: 'table-cell'}}>
                {
                    !locker.unlocked && isFuture(locker.unlockAt) ?
                        formatDistanceToNow(locker.unlockAt.getTime()) : "-"
                }
            </Td>
            <Td>
                {
                    formatDistanceToNow(locker.lockedAt)
                } ago
            </Td>
        </Link>
    )
}
