import * as React from "react";
import {Center, Heading, HStack} from "@chakra-ui/react";
import {FaHammer} from "react-icons/all";

export function ComingSoon(){
    return (
        <Center h='100%'>
            <HStack spacing='10px' color='fontAccent'>
                <FaHammer size='25px' />
                <Heading fontSize='lg'>
                    Coming soon...
                </Heading>
            </HStack>
        </Center>
    )
}