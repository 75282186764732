import {NftMarketplace} from "../../types/NftMarketplace";
import {Mainnet, Polygon, Rinkeby} from "@usedapp/core";
import {ERC721Tokenish} from "../Assets";

const testnetUri = "https://testnets.opensea.io/assets"
const productionUri = "https://opensea.io/assets"

const baseUris: {[chainId: number]: string} = {
    // Testnets
    [Rinkeby.chainId]: testnetUri,

    // Mainnets
    [Mainnet.chainId]: productionUri,
    [Polygon.chainId]: productionUri + "matic/",
}

export const opensea: NftMarketplace = {
    name: "OpenSea",
    logos: {
        hoverIcon: "/images/opensea/hoverIcon.png",
    },
    theming: {
        gradientStart: "",
        gradientEnd: "",
    },

    viewUri: (nft: ERC721Tokenish) => {
        return `${baseUris[nft.chainId]}/${nft.address}/${nft.tokenId}`
    },

    listLocker: (nft: ERC721Tokenish) => {

    },
    isListed: (nft: ERC721Tokenish) => {

    }
}