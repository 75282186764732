import {ERC20__factory} from "../contracts";
import {useRpc} from "./useRpc";
import {useEffect, useState} from "react";
import {ERC20Token} from "../helpers/Assets";
import {ethers} from "ethers";

export const useToken = function (chainId: number, tokenAddress: string){
    const provider = useRpc(chainId)
    const [token, updateToken] = useState<ERC20Token | null>(null)

    useEffect(() => {
        if (ethers.utils.isAddress(tokenAddress)){
            const contract = ERC20__factory.connect(tokenAddress, provider)
            contract.name().then(async (name) => {
                updateToken({
                    address: tokenAddress,
                    chainId: chainId,
                    name: name,
                    symbol: await contract.symbol(),
                    decimals: await contract.decimals(),
                })
            })
        }else{
            updateToken(null)
        }

    }, [chainId, tokenAddress])

    return token
}