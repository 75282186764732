import {NftMarketplace} from "../../types/NftMarketplace";
import {Avalanche, BSC, Mainnet, Polygon, Rinkeby} from "@usedapp/core";
import {AvaxFuji} from "../../config/chains/AvaxFuji";
import {ERC721Tokenish} from "../Assets";

const baseUris: {[chainId: number]: string} = {
    // Testnets
    [Rinkeby.chainId]: "https://testnets.nftrade.com/assets/rin",
    [AvaxFuji.chainId]: "https://testnets.nftrade.com/assets/fuji",

    // Mainnets
    [Mainnet.chainId]: "https://nftrade.com/assets/eth",
    [BSC.chainId]: "https://nftrade.com/assets/bsc",
    [Polygon.chainId]: "https://nftrade.com/assets/polygon",
    [Avalanche.chainId]: "https://nftrade.com/assets/avalanche"
}

export const nftrade: NftMarketplace = {
    name: "NFTrade",
    logos: {
        hoverIcon: "/images/nftrade/logo_dark.png"
    },
    theming: {
        gradientStart: "",
        gradientEnd: "",
    },

    viewUri: (nft: ERC721Tokenish) => {
        return `${baseUris[nft.chainId]}/${nft.address}/${nft.tokenId}`
    },

    listLocker: (nft: ERC721Tokenish) => {

    },
    isListed: (nft: ERC721Tokenish) => {

    }
}