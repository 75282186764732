import {
    Avalanche,
    BSC,
    BSCTestnet,
    Config, Cronos, CronosTestnet, Gnosis,
    Mainnet,
    Mumbai, Polygon,
    Rinkeby,
    Ropsten
} from "@usedapp/core";
import {
    ERC20Token,
    ERC721Token, fetchERC20AssetsFromBlockscout,
    fetchERC20AssetsFromEtherscan, fetchERC721AssetsFromBlockscout,
    fetchERC721AssetsFromEtherscan, fetchMockERC20s, fetchMockERC721s
} from "../helpers/Assets";
import {Hardhat} from "./chains/Hardhat";
import {AvaxFuji} from "./chains/AvaxFuji";
import {NftMarketplace} from "../types/NftMarketplace";
import {nftrade} from "../helpers/marketplaces/nftrade";
import {opensea} from "../helpers/marketplaces/opensea";
import {Ethereum} from "./chains/Ethereum";
import {TestMilkomeda} from "./chains/TestMilkomeda";
import {MilkomedaCardano} from "./chains/MilkomedaCardano";
import {EVMOS} from "./chains/EVMOS";

/**
 * UseDapp config
 */
const config: Config = {
    readOnlyChainId: Hardhat.chainId,
    networks: [
        /* Stage 1 */
        Ethereum,
        Polygon,
        Gnosis,
        Avalanche,
        MilkomedaCardano,
        Cronos,
        BSC,
        EVMOS,

        //Hardhat,

        /* Stage 2 */
        // Arbitrum
        // Optimism

        /* Stage 3 */

        //Fantom
        // Gnosis

        // Testnets
        Rinkeby,
        BSCTestnet,
        Mumbai,
        AvaxFuji,
        //TestMilkomeda,
        //CronosTestnet,
    ],
    readOnlyUrls: {
        // Mainnets
        [Ethereum.chainId]: 'https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161',
        [Gnosis.chainId]: 'https://rpc.gnosischain.com/',
        [Cronos.chainId]: "https://evm.cronos.org/",
        [BSC.chainId]: 'https://bsc-dataseed.binance.org/',
        [Polygon.chainId]: 'https://polygon-rpc.com',
        [Avalanche.chainId]: 'https://api.avax.network/ext/bc/C/rpc',
        [MilkomedaCardano.chainId]: "https://rpc-mainnet-cardano-evm.c1.milkomeda.com",
        [EVMOS.chainId]: "https://eth.bd.evmos.org:8545",

        // Testnets
        //[Hardhat.chainId]: 'http://127.0.0.1:8545/',
        [Rinkeby.chainId]: 'https://rinkeby.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161',
        [BSCTestnet.chainId]: "https://data-seed-prebsc-1-s1.binance.org:8545/",
        [Mumbai.chainId]: 'https://rpc-mumbai.maticvigil.com/v1/2b118b00c33c1c7a8def534d3b00ef4516cd4034',
        [AvaxFuji.chainId]: 'https://api.avax-test.network/ext/bc/C/rpc',
        [TestMilkomeda.chainId]: "https://rpc-devnet-cardano-evm.c1.milkomeda.com",
    },
}

/**
 *
 */

const OwnedLockersChains: number[] = [
    Ethereum.chainId,
    Cronos.chainId,
    MilkomedaCardano.chainId,
    Gnosis.chainId,
    BSC.chainId,
    Polygon.chainId,
    Avalanche.chainId,
    EVMOS.chainId,
];

const NewestLockersChains: number[] = [
    Ethereum.chainId,
    Cronos.chainId,
    MilkomedaCardano.chainId,
    Gnosis.chainId,
    BSC.chainId,
    Polygon.chainId,
    Avalanche.chainId,
    EVMOS.chainId,
];

const ProductionChains: number[] = [
    Ethereum.chainId,
    Cronos.chainId,
    MilkomedaCardano.chainId,
    Gnosis.chainId,
    BSC.chainId,
    Polygon.chainId,
    Avalanche.chainId,
    EVMOS.chainId,
];

const TestnetChains: number[] = [
    Rinkeby.chainId,
    BSCTestnet.chainId,
    Mumbai.chainId,
    AvaxFuji.chainId,
    TestMilkomeda.chainId,
    CronosTestnet.chainId,
];

/**
 * Custom config
 */
const ChainConfig: {[chainId: number]: ChainConfigOptions} = {
    [Ethereum.chainId]: {
        assets: {
            trustWalletChainSlug: "ethereum",
            tokenList: "https://gateway.ipfs.io/ipns/tokens.uniswap.org",

            ERC20Provider: {
                name: "Etherscan",
                url: "https://etherscan.io/"
            },
            ERC20Handler: (account) => fetchERC20AssetsFromEtherscan(Mainnet.chainId, "https://api.etherscan.io", account),

            ERC721Provider: {
                name: "Etherscan",
                url: "https://etherscan.io/"
            },
            ERC721Handler: (account) => fetchERC721AssetsFromEtherscan(Mainnet.chainId, "https://api.etherscan.io", account)
        },
        theming: {
            gradientStart: '#cdaefb',
            gradientEnd: '#80a4f8'
        },
        marketplaces: [
            opensea
        ],
    },

    [Hardhat.chainId]: {
        assets: {
            tokenList: "https://raw.githubusercontent.com/btswap/rinkeby/master/tokenlist.json",

            ERC20Provider: {
                name: "Mocking",
                url: "https://rinkeby.etherscan.io/"
            },
            ERC20Handler: (account) => fetchMockERC20s(),

            ERC721Provider: {
                name: "Etherscan",
                url: "https://rinkeby.etherscan.io/"
            },
            ERC721Handler: (account) => fetchMockERC721s(),
        },
        theming: {
            gradientStart: '#ccbf2f',
            gradientEnd: '#c4ac10'
        },
        marketplaces: [],
    },


    [Rinkeby.chainId]: {
        assets: {
            tokenList: "https://raw.githubusercontent.com/btswap/rinkeby/master/tokenlist.json",

            ERC20Provider: {
                name: "Etherscan",
                url: "https://rinkeby.etherscan.io/"
            },
            ERC20Handler: (account) => fetchERC20AssetsFromEtherscan(Rinkeby.chainId, "https://api-rinkeby.etherscan.io", account),

            ERC721Provider: {
                name: "Etherscan",
                url: "https://rinkeby.etherscan.io/"
            },
            ERC721Handler: (account) => fetchERC721AssetsFromEtherscan(Rinkeby.chainId, "https://api-rinkeby.etherscan.io", account)
        },
        theming: {
            gradientStart: '#2f73cc',
            gradientEnd: '#1099c4'
        },
        marketplaces: [
            opensea,
            nftrade
        ],
    },

    [Ropsten.chainId]: {
        assets: {
            tokenList: "https://raw.githubusercontent.com/euler-xyz/euler-tokenlist/master/euler-tokenlist-ropsten.json",

            ERC20Provider: {
                name: "Etherscan",
                url: "https://ropsten.etherscan.io/"
            },
            ERC20Handler: (account) => fetchERC20AssetsFromEtherscan(Ropsten.chainId, "https://api-ropsten.etherscan.io", account),

            ERC721Provider: {
                name: "Etherscan",
                url: "https://ropsten.etherscan.io/"
            },
            ERC721Handler: (account) => fetchERC721AssetsFromEtherscan(Ropsten.chainId, "https://api-ropsten.etherscan.io", account)
        },
        theming: {
            gradientStart: '#2f73cc',
            gradientEnd: '#1099c4'
        },
        marketplaces: [],
    },

    [Cronos.chainId]: {
        assets: {
            tokenList: "https://swap.crodex.app/tokens.json",

            ERC20Provider: {
                name: "CronoScan",
                url: "https://cronoscan.com/"
            },
            ERC20Handler: (account) => fetchERC20AssetsFromEtherscan(Cronos.chainId, "https://api.cronoscan.com", account),

            ERC721Provider: {
                name: "CronoScan",
                url: "https://cronoscan.com/"
            },
            ERC721Handler: (account) => fetchERC721AssetsFromEtherscan(Cronos.chainId, "https://api.cronoscan.com", account)
        },
        theming: {
            gradientStart: '#011e3e',
            gradientEnd: '#002967'
        },
        marketplaces: [],
    },

    [Gnosis.chainId]: {
        assets: {
            tokenList: "https://raw.githubusercontent.com/1Hive/default-token-list/master/src/tokens/xdai.json",

            ERC20Provider: {
                name: "Blockscout",
                url: "https://blockscout.com/xdai/mainnet/",
            },
            ERC20Handler: (account) => fetchERC20AssetsFromBlockscout(Gnosis.chainId, "https://blockscout.com/xdai/mainnet", account),

            ERC721Provider: {
                name: "Blockscout",
                url: "https://blockscout.com/xdai/mainnet/",
            },
            ERC721Handler: (account) => fetchERC721AssetsFromBlockscout(Gnosis.chainId, "https://blockscout.com/xdai/mainnet", account),
        },
        theming: {
            gradientStart: '#82ccbb',
            gradientEnd: '#5ed0b3'
        },
        marketplaces: [],
    },

    [BSC.chainId]: {
        assets: {
            tokenList: "https://tokens.pancakeswap.finance/pancakeswap-extended.json",

            ERC20Provider: {
                name: "BscScan",
                url: "https://testnet.bscscan.com/"
            },
            ERC20Handler: (account) => fetchERC20AssetsFromEtherscan(BSC.chainId, "https://api.bscscan.com", account),

            ERC721Provider: {
                name: "BscScan",
                url: "https://testnet.bscscan.com/"
            },
            ERC721Handler: (account) => fetchERC721AssetsFromEtherscan(BSC.chainId, "https://api.bscscan.com", account)
        },
        theming: {
            gradientStart: '#f2b92b',
            gradientEnd: '#f2b92b'
        },
        marketplaces: [
            nftrade,
        ],
    },

    [BSCTestnet.chainId]: {
        assets: {
            tokenList: "https://raw.githubusercontent.com/MarsEcosystem/mars-token-list/master/mars.bsc.testnet.tokenlist.json",

            ERC20Provider: {
                name: "BscScan",
                url: "https://bscscan.com/"
            },
            ERC20Handler: (account) => fetchERC20AssetsFromEtherscan(BSCTestnet.chainId, "https://api-testnet.bscscan.com", account),

            ERC721Provider: {
                name: "BscScan",
                url: "https://bscscan.com/"
            },
            ERC721Handler: (account) => fetchERC721AssetsFromEtherscan(BSCTestnet.chainId, "https://api-testnet.bscscan.com", account)
        },
        theming: {
            gradientStart: '#f2b92b',
            gradientEnd: '#f2b92b'
        },
        marketplaces: [],
    },

    [Polygon.chainId]: {
        assets: {
            tokenList: "https://tokens.uniswap.org/",

            ERC20Provider: {
                name: "PolygonScan",
                url: "https://polygonscan.com/"
            },
            ERC20Handler: (account) => fetchERC20AssetsFromEtherscan(Polygon.chainId, "https://api.polygonscan.com", account),

            ERC721Provider: {
                name: "PolygonScan",
                url: "https://polygonscan.com/"
            },
            ERC721Handler: (account) => fetchERC721AssetsFromEtherscan(Polygon.chainId, "https://api.polygonscan.com", account)
        },
        theming: {
            gradientStart: '#7B3FE4',
            gradientEnd: '#6433B9'
        },
        marketplaces: [
            opensea,
            nftrade
        ],
    },

    [Mumbai.chainId]: {
        assets: {
            tokenList: "https://raw.githubusercontent.com/elkfinance/tokens/main/mumbai.tokenlist.json",

            ERC20Provider: {
                name: "PolygonScan",
                url: "https://mumbai.polygonscan.com/"
            },
            ERC20Handler: (account) => fetchERC20AssetsFromEtherscan(Mumbai.chainId, "https://api-testnet.polygonscan.com", account),

            ERC721Provider: {
                name: "PolygonScan",
                url: "https://mumbai.polygonscan.com/"
            },
            ERC721Handler: (account) => fetchERC721AssetsFromEtherscan(Mumbai.chainId, "https://api-testnet.polygonscan.com", account)
        },
        theming: {
            gradientStart: '#7B3FE4',
            gradientEnd: '#6433B9'
        },
        marketplaces: [],
    },

    [Avalanche.chainId]: {
        assets: {
            tokenList: "https://raw.githubusercontent.com/traderjoe-xyz/joe-tokenlists/main/joe.tokenlist.json",

            ERC20Provider: {
                name: "SnowTrace",
                url: "https://snowtrace.io/"
            },
            ERC20Handler: (account) => fetchERC20AssetsFromEtherscan(Avalanche.chainId, "https://api.snowtrace.io", account),

            ERC721Provider: {
                name: "SnowTrace",
                url: "https://snowtrace.io/"
            },
            ERC721Handler: (account) => fetchERC721AssetsFromEtherscan(Avalanche.chainId, "https://api.snowtrace.io", account)
        },
        theming: {
            gradientStart: '#e43a15',
            gradientEnd: '#e65245'
        },
        marketplaces: [
            nftrade
        ],
    },

    [AvaxFuji.chainId]: {
        assets: {
            tokenList: "https://raw.githubusercontent.com/elkfinance/tokens/main/fuji.tokenlist.json",

            ERC20Provider: {
                name: "SnowTrace",
                url: "https://testnet.snowtrace.io/"
            },
            ERC20Handler: (account) => fetchERC20AssetsFromEtherscan(AvaxFuji.chainId, "https://api-testnet.snowtrace.io", account),

            ERC721Provider: {
                name: "SnowTrace",
                url: "https://testnet.snowtrace.io/"
            },
            ERC721Handler: (account) => fetchERC721AssetsFromEtherscan(AvaxFuji.chainId, "https://api-testnet.snowtrace.io", account)
        },
        theming: {
            gradientStart: '#e43a15',
            gradientEnd: '#e65245'
        },
        marketplaces: [
            nftrade
        ],
    },

    [MilkomedaCardano.chainId]: {
        assets: {
            tokenList: "https://raw.githubusercontent.com/elkfinance/tokens/main/mumbai.tokenlist.json",

            ERC20Provider: {
                name: "Blockscout",
                url: "https://explorer-mainnet-cardano-evm.c1.milkomeda.com"
            },
            ERC20Handler: (account) => fetchERC20AssetsFromBlockscout(MilkomedaCardano.chainId, "https://explorer-mainnet-cardano-evm.c1.milkomeda.com", account),

            ERC721Provider: {
                name: "Blockscout",
                url: "https://explorer-mainnet-cardano-evm.c1.milkomeda.com"
            },
            ERC721Handler: (account) => fetchERC721AssetsFromBlockscout(MilkomedaCardano.chainId, "https://explorer-mainnet-cardano-evm.c1.milkomeda.com", account)
        },
        theming: {
            gradientStart: '#030909',
            gradientEnd: '#030909'
        },
        marketplaces: [],
    },

    [TestMilkomeda.chainId]: {
        assets: {
            tokenList: "https://raw.githubusercontent.com/elkfinance/tokens/main/mumbai.tokenlist.json",

            ERC20Provider: {
                name: "Blockscout",
                url: "https://explorer-devnet-cardano-evm.c1.milkomeda.com"
            },
            ERC20Handler: (account) => fetchERC20AssetsFromBlockscout(TestMilkomeda.chainId, "https://explorer-devnet-cardano-evm.c1.milkomeda.com", account),

            ERC721Provider: {
                name: "Blockscout",
                url: "https://explorer-devnet-cardano-evm.c1.milkomeda.com"
            },
            ERC721Handler: (account) => fetchERC721AssetsFromBlockscout(TestMilkomeda.chainId, "https://explorer-devnet-cardano-evm.c1.milkomeda.com", account)
        },
        theming: {
            gradientStart: '#7B3FE4',
            gradientEnd: '#6433B9'
        },
        marketplaces: [],
    },

    [EVMOS.chainId]: {
        assets: {
            tokenList: "https://raw.githubusercontent.com/elkfinance/tokens/main/mumbai.tokenlist.json",

            ERC20Provider: {
                name: "Blockscout",
                url: "https://evm.evmos.org/"
            },
            ERC20Handler: (account) => fetchERC20AssetsFromBlockscout(MilkomedaCardano.chainId, "https://evm.evmos.org/", account),

            ERC721Provider: {
                name: "Blockscout",
                url: "https://evm.evmos.org/"
            },
            ERC721Handler: (account) => fetchERC721AssetsFromBlockscout(MilkomedaCardano.chainId, "https://evm.evmos.org/", account)
        },
        theming: {
            gradientStart: '#ed4e33',
            gradientEnd: '#ed4e33'
        },
        marketplaces: [],
    },
}

interface ChainConfigOptions {
    assets: {
        trustWalletChainSlug?: string,
        tokenList: string,

        ERC20Provider: {
            name: string,
            url: string,
        }
        ERC20Handler: (account: string) => Promise<ERC20Token[]>,

        ERC721Provider: {
            name: string,
            url: string,
        },
        ERC721Handler: (account: string) => Promise<ERC721Token[]>,
    }
    theming: {
        gradientStart: string,
        gradientEnd: string
    },
    marketplaces: NftMarketplace[]
}

export {config, ChainConfig, OwnedLockersChains, NewestLockersChains, ProductionChains, TestnetChains}