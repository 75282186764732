import deploymentsRaw from '../deployments.json'
import {MultiExport} from "../types/DeployExport";
import {ethers} from "ethers";
import {config} from "../config/config";
import {LockerFactory__factory} from "../contracts";

const deployments: MultiExport = deploymentsRaw
const factoryName = "LockerFactory"

export function getContract(chainId: number, contractName: string){
    const deployment = deployments[chainId]

    if (deployment === undefined){
        return undefined
    }

    if (!deployment[0].contracts.hasOwnProperty(contractName)){
        return undefined;
    }

    return deployment[0].contracts[contractName].address
}

export function getFactory(chainId: number, provider?: ethers.providers.Provider){
    const factoryAddress = getContract(chainId, factoryName)

    const newProvider = provider ? provider : new ethers.providers.JsonRpcProvider(
        config.readOnlyUrls? config.readOnlyUrls[chainId] : "",
    );

    return LockerFactory__factory.connect(factoryAddress ? factoryAddress : "d3ad", newProvider);
}