import {Box, Text, TableCaption, Center} from "@chakra-ui/react"
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th
} from '@chakra-ui/react'
import { ILocker, LockerReference} from "../../helpers/Lockers";
import * as React from "react";
import {LockerItem} from "./LockerItem";
import {LockerItemLoading} from "./LockerItemLoading";

interface lockerListProps{
    lockers: LockerReference[] | ILocker[] | undefined
}

export function LockerList(props: lockerListProps) {

    return (
        <Box borderRadius={5} mb="40px" borderWidth="1px" backgroundColor='container' borderColor='containerBorder' pt='5px'>
            <Table size='sm'>
                <Thead>
                    <Tr>
                        <Th w="10px"></Th>
                        <Th>ID</Th>
                        <Th display={{base: 'none', md: 'table-cell'}}>Locker</Th>
                        <Th display={{base: 'none', sm: 'table-cell'}}>Locked for</Th>
                        <Th>Created</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {
                        props.lockers !== undefined && props.lockers.length ? props.lockers.map((lockerRef) => {
                            return <LockerItem key={`${lockerRef.chainId}:${lockerRef.id}`} locker={lockerRef} />
                        }) : props.lockers !== undefined && props.lockers.length === 0 ?
                            ""
                            :
                            <>
                                <LockerItemLoading />
                                <LockerItemLoading />
                                <LockerItemLoading />
                                <LockerItemLoading />
                            </>
                    }
                </Tbody>
                {
                    props.lockers !== undefined && props.lockers.length === 0 ?
                         <TableCaption mt="0px" fontWeight='600' minHeight="50px">
                             <Center h="50px">
                                 <Text variant='support'>No lockers found</Text>
                             </Center>
                         </TableCaption>
                    :
                        false
                }
            </Table>
        </Box>
    )
}
