import {ChakraProvider, ColorModeScript} from "@chakra-ui/react"
import * as React from "react"
import ReactDOM from "react-dom"
import { App } from "./App"
import reportWebVitals from "./reportWebVitals"
import * as serviceWorker from "./serviceWorker"
import {BrowserRouter} from "react-router-dom";
import theme from "./config/theme";
import {Helmet} from "react-helmet";
import { chain, createClient, WagmiProvider } from 'wagmi';
import "@fontsource/ibm-plex-mono"
import {RainbowKitProvider, configureChains, getDefaultWallets, apiProvider, darkTheme} from "@rainbow-me/rainbowkit";
import {Chain} from "@rainbow-me/rainbowkit/dist/components/RainbowKitProvider/RainbowKitChainContext";
import {config} from "./config/config";
import {getWAGMIChainConfigs} from "./helpers/ChainHelper";

import '@rainbow-me/rainbowkit/styles.css';

const { chains, provider } = configureChains(
    getWAGMIChainConfigs(),
    [
        apiProvider.jsonRpc((chain: Chain) => {
            return {
                rpcUrl: config.readOnlyUrls ? config.readOnlyUrls[chain.id] : ""
            }
        })
    ]
);

const { connectors } = getDefaultWallets({
    appName: 'My RainbowKit App',
    chains
});

const wagmiClient = createClient({
    autoConnect: true,
    connectors,
    provider
})


// import "@fontsource/source-sans-pro"
// import "@fontsource/karla"
// import "@fontsource/open-sans"


ReactDOM.render(
  <React.StrictMode>
      <Helmet titleTemplate="DuoCash - %s">
          <title>Dashboard</title>
          <meta name="description" content="Helmet application" />
      </Helmet>
      <ColorModeScript initialColorMode={'dark'} />
      <WagmiProvider client={wagmiClient}>
          <RainbowKitProvider chains={chains} theme={{ ...darkTheme(), colors: {...darkTheme().colors, accentColor: "rgba(255, 255, 255, 0.20)", connectButtonBackground: "rgba(255, 255, 255, 0.03)"}}}>
              <ChakraProvider theme={theme}>
              <BrowserRouter>
                  <App />
              </BrowserRouter>
            </ChakraProvider>
          </RainbowKitProvider>
      </WagmiProvider>
  </React.StrictMode>,
  document.getElementById("root"),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
