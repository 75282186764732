import {Badge} from "@chakra-ui/react";
import * as React from "react";
import {useChainMeta} from "../../hooks/useChainMeta";

export function ChainSelectorToggle(props: {id: number, active: boolean, onClick: () => void}) {
    const chain = useChainMeta(props.id);

    return (
        <Badge
            variant={ props.active ? "solid" : "outline"}
            onClick={ props.onClick }
            colorScheme={chain?.isTestChain ? "purple" : undefined}
            cursor="pointer"
            mx="2px"
        >
            {chain?.chainName}
        </Badge>
    );
}