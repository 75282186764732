import {Button, Flex, Spacer, VStack} from "@chakra-ui/react"
import * as React from "react";
import {AiOutlineMenu} from "react-icons/all";
import {useContext} from "react";
import menuContext from "../context/menuContext";

export function TopBar() {
    const {setExpanded} = useContext(menuContext)
    const showOnMobile = {base: 'inherit', md: 'none'}

    return (
        <VStack position='absolute' top='0px' left='0px' width='100%'>
            <Flex w="100%" p="5">
                <Button size='sm' onClick={() => setExpanded(true)} display={showOnMobile} >
                    <AiOutlineMenu />
                </Button>

                <Spacer />
            </Flex>
        </VStack>
    )
  }
  