import {Center, Text} from "@chakra-ui/react";

export function BetaMessage() {
    return (
        <Center w='100%' bg='container' py="10px">
            <Text fontSize='xs'>
                This product has not been audited yet, usage is at your own risk
            </Text>
        </Center>
    )
}