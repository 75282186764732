import {
    Box, Img, Spacer,
    useMediaQuery,
    VStack
} from "@chakra-ui/react"
import * as React from "react";
import {MenuItem} from "../components/MenuItem/MenuItem"
import {
    RiDashboardLine,
    RiSafe2Line,
    BsPeople,
    RiQuestionnaireLine, AiOutlineFieldTime, FiTwitter, FaTelegramPlane
} from "react-icons/all";
import {MenuHeader} from "../components/MenuHeader/MenuHeader";
import {useContext, useMemo, useState} from "react";
import menuContext from "../context/menuContext";
import {getAllowTestnets, setAllowTestnets} from "../helpers/ChainHelper";

export function Sidebar({...props}) {
    const {expanded, setExpanded} = useContext(menuContext)
    const [isMobile] = useMediaQuery('(max-width: 767px)')
    const [logoClicks, updateLogoClicks] = useState<number>(0);

    const onLogoClick = function () {
        updateLogoClicks(logoClicks + 1)

        if (logoClicks === 5){
            setAllowTestnets(!getAllowTestnets());
            window.location.reload();
        }
    }

    const {w, ...other} = props
    const width = expanded ? "250px" : w

    let hidden = useMemo(() => {
        if (!isMobile){
            return false
        }

        return !expanded;
    }, [expanded, isMobile]);

    let backdrop = useMemo(() => {
        return isMobile && expanded
    }, [expanded, isMobile]);

    return (
        <Box zIndex={isMobile ? 100000 : undefined} mr={isMobile ? "0" : w}>
                <VStack
                    py='30px'
                    w={width}
                    alignItems={expanded ? 'left' : 'center'}
                    px={expanded ? '20px' : undefined}
                    spacing='2px'
                    bg={isMobile ? '#212733' : 'container' }

                    height="100vh"
                    display={hidden ? "none" : "inline-flex"}
                    position={"fixed"}
                    // transition={"all .2s ease"}
                >
                    <Img src='/images/logo_2048.JPG' height='50px' width='50px' borderRadius='5%' mb='10px' onClick={onLogoClick}  />
                    {/*<Box height='50px' width='50px' borderRadius='5%' mb='10px' bgGradient='linear(to-br, #43e97b, #38f9d7)'/>*/}

                    <MenuHeader label={'Dashboard'} >
                        <MenuItem
                            label='Dashboard'
                            uri='/'
                            icon={<RiDashboardLine />}
                            expanded={expanded}
                        />
                    </MenuHeader>

                    <MenuHeader label={'Lockers'} >
                        <MenuItem
                            label='Newest lockers'
                            uri='/lockers/new'
                            icon={<AiOutlineFieldTime />}
                            expanded={expanded}
                        />

                        <MenuItem
                            label='My lockers'
                            uri='/lockers/owned'
                            icon={<RiSafe2Line />}
                            expanded={expanded}
                        />
                    </MenuHeader>

                    {/*<MenuHeader label={'Docs'} >*/}
                    {/*    <MenuItem*/}
                    {/*        label='Developers'*/}
                    {/*        uri='/developers'*/}
                    {/*        icon={<GoFileCode />}*/}
                    {/*        expanded={expanded}*/}
                    {/*    />*/}

                    {/*    <MenuItem*/}
                    {/*        label='Help'*/}
                    {/*        uri='/docs'*/}
                    {/*        icon={<RiQuestionnaireLine />}*/}
                    {/*        expanded={expanded}*/}
                    {/*    />*/}
                    {/*</MenuHeader>*/}

                    <MenuHeader label={'DuoCash'} >
                        <MenuItem
                            label='Documentation'
                            uri='https://docs.duo.cash/'
                            target="_blank"
                            icon={<RiQuestionnaireLine />}
                            expanded={expanded}
                        />
                        <MenuItem
                            label='About'
                            uri='/about'
                            icon={<BsPeople />}
                            expanded={expanded}
                        />
                    </MenuHeader>

                    <MenuHeader label={'Social'} >
                        <MenuItem
                            label='Telegram'
                            uri='https://t.me/duo_cash'
                            target='_blank'
                            icon={<FaTelegramPlane />}
                            expanded={expanded}
                        />

                        <MenuItem
                            label='Twitter'
                            uri='https://twitter.com/duocash'
                            icon={<FiTwitter />}
                            target='_blank'
                            expanded={expanded}
                        />
                    </MenuHeader>

                    {/*<MenuHeader label={'Analytics'} >*/}
                    {/*    <MenuItem*/}
                    {/*        label='Analytics'*/}
                    {/*        uri='/analytics'*/}
                    {/*        icon={<IoMdAnalytics />}*/}
                    {/*        expanded={expanded}*/}
                    {/*    />*/}
                    {/*</MenuHeader>*/}
                    {/*<Button size='xs' onClick={() => setExpanded(!expanded)} w='60px'>*/}
                    {/*    Toggle*/}
                    {/*</Button>*/}
                    <Spacer />
                </VStack>

                {/* Catches click events for the backdrop */}
                <Box
                    display={backdrop ? "block" : "none"}
                    w='100%'
                    h='100%'
                    onClick={() => setExpanded(false)}
                 />
            {/*</Slide>*/}

            {/* Backdrop for mobile menu */}
            <Box
                display={backdrop ? "block" : "none"}
                position="absolute"
                zIndex={-1}
                bg='rgba(0, 0, 0, 0.45)'
                w='100vw'
                h='100vh'
                overflow='none'
                top='0'
                left='0'
                onClick={() => setExpanded(false)}
            />
        </Box>
    )
  }
  