import {Box, Button, Center, Text} from "@chakra-ui/react";
import * as React from "react";
import {ChainLogo} from "../ChainLogo/ChainLogo";
import {ChainConfig, config} from "../../config/config";
import { useNetwork } from 'wagmi'
import { ConnectButton } from '@rainbow-me/rainbowkit';
import {isChainSupported} from "../../helpers/ChainHelper";
import {useEffect} from "react";
import {getChainMeta} from "../../hooks/useChainMeta";

interface ChainPickerOptions {
    // User is not allowed to pick a chain but has to switch to the one given
    forceChain?: number
    // Will callback when a user has initiated a chain switch but has not finished it
    onStateChange?: (ready: boolean) => void
}

export function ChainPicker(props: ChainPickerOptions){
    const {
        activeChain,
        switchNetwork,
        pendingChainId
    } = useNetwork()

    useEffect(() => {
        if(props.onStateChange) {
            const ready = props.forceChain !== undefined ? activeChain?.id === props.forceChain : isChainSupported(activeChain?.id);
            props.onStateChange(ready);
        }
    }, [props.forceChain, activeChain, props.onStateChange])

    return (
        <ConnectButton.Custom>
            {({
                  account,
                  chain,
                  openAccountModal,
                  openChainModal,
                  openConnectModal,
                  mounted,
              }) => {
                const chainColors = ChainConfig[chain ? chain.id : 1].theming

                return account && chain ?
                    <>
                    <Center
                        w='100%'
                        h='80px'
                        bgGradient={`linear(to-br, ${chainColors.gradientStart}, ${chainColors.gradientEnd})`}
                        borderRadius='13px'
                        mb='0px'
                        px='15px'
                        borderBottomRadius={'13px'}
                    >
                        <ChainLogo chainId={chain.id} h='50px' w='50px'/>
                        {
                            props.forceChain === undefined ?
                                <Text fontSize='24px' fontWeight='800' ml='10px' w='100%' onClick={openChainModal}>
                                    {chain.name}
                                </Text>
                                :
                                <Text fontSize='24px' fontWeight='800' ml='10px' w='100%'>
                                    {chain.name}
                                </Text>
                        }

                    </Center>

                    {
                        account && props.forceChain !== undefined && props.forceChain !== chain.id && config.networks ?
                            <Box
                                bg='rgba(0, 0, 0, 0.15)'
                                marginTop='0px !important'
                                borderBottomRadius='13px'
                                p='10px'
                            >
                                <Center>
                                    <Button onClick={() => {
                                        if(switchNetwork) switchNetwork(props.forceChain)
                                    }} isLoading={!!pendingChainId}>
                                        Switch to {getChainMeta(props.forceChain)?.chainName}
                                    </Button>
                                </Center>
                            </Box>
                            : ""
                    }
                    </>
                    :
                    <Center
                        w='100%'
                        h='80px'
                        bg='rgba(0, 0, 0, 0.15)'
                        borderRadius='13px'
                        mb='0px'
                        px='15px'
                    >
                        <Button onClick={openConnectModal}>
                            Connect your wallet
                        </Button>
                    </Center>
                }
            }
        </ConnectButton.Custom>

    )
}