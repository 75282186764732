import {fetchLocker, fetchLockerImplementation, ILocker} from "../../../helpers/Lockers";

import {
    Text,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    TableContainer, Container, SimpleGrid, Box, Heading, Center, Spinner,
} from '@chakra-ui/react'
import {BigNumber, ethers} from "ethers";
import {PageHeading} from "../../../partials/PageHeading";
import {useEffect, useMemo, useState} from "react";
import {useParams} from "react-router-dom";
import {delay, ERC20Token, ERC721Token, fetchAssets, fetchNFTs} from "../../../helpers/Assets";
import {LockerImplementation} from "../../../types/LockerImplementation";
import * as React from "react";
import {SimpleWithdrawal__factory} from "../../../contracts";
import {useEthers} from "@usedapp/core";
import {SimpleWithdrawERC20Item} from "../../../components/SimpleWithdrawItems/SimpleWithdrawERC20Item";
import {SimpleWithdrawNFTItem} from "../../../components/SimpleWithdrawItems/SimpleWithdrawNFTItem";

export function SimpleWithdrawal(){
    let urlParams = useParams();
    const lockerChainId = parseInt(urlParams["chainSlug"] ?? "");
    const {account, library, chainId} = useEthers()

    const [locker, updateLocker] = useState<ILocker | null>(null)
    const [implementation, updateImplementation] = useState<LockerImplementation | undefined>(undefined)
    const [assets, updateAssets] = useState<ERC20Token[] | 'loading'>('loading')
    const [nfts, updateNFTs] = useState<ERC721Token[] | 'loading'>('loading')

    const lockerId = useMemo(() => {
        if(urlParams["lockerId"]){
            if (ethers.utils.isAddress(urlParams["lockerId"])){
                return urlParams["lockerId"]
            }else{
                return parseInt(urlParams["lockerId"])
            }
        }

        return 0
    }, [lockerChainId, urlParams["lockerId"]])

    const refreshLocker = () => {
        fetchLocker(lockerChainId, lockerId).then((locker) => {
            locker.refresh = refreshLocker
            updateLocker(locker)

            if (locker.unlocked){
                fetchLockerImplementation(locker).then((imp) => {
                    updateImplementation(imp)
                })
            }

            updateAssets('loading')
            fetchAssets(locker.chainId, locker.address).then(async (tokens) => {
                updateAssets(tokens)

                // We have to delay this request so we don't get throttled by etherscan
                delay(5000).then(() => {
                    fetchNFTs(locker.chainId, locker.address).then((nfts) => {
                        updateNFTs(nfts)
                    });
                })
            });
        })
    }

    useEffect(() => {
        updateLocker(null)
        refreshLocker()
    }, [lockerChainId, lockerId])


    const withdrawAsset = async (type: string, details: {address?: string, amount?: BigNumber, id?: BigNumber | string | number}) => {
        // Verify everything needed has been loaded
        if (!library || !account || !locker || chainId !== locker.chainId){
            return "Something went wrong, please refresh and try again"
        }

        const withdrawal = SimpleWithdrawal__factory.connect(locker.address, library.getSigner())
        console.log(await withdrawal.owner())

        if (type === "native"){
            if (details.amount === undefined){
                return "Missing amount";
            }

            return withdrawal.withdraw(
                account,
                details.amount,
                [],
                [],
                [],
                [],
                [],
                [],
                []
            );
        } else if (type === "erc20"){
            if (details.address === undefined || details.amount === undefined){
                return "Missing address or amount";
            }

            return withdrawal.withdraw(
                account,
                0,
                [
                    details.address
                ],
                [
                    details.amount
                ],
                [],
                [],
                [],
                [],
                []
            );
        }else if (type === "erc721"){
            if (details.address === undefined || details.id === undefined){
                return "Missing address or id";
            }

            return withdrawal.withdraw(
                account,
                0,
                [],
                [],
                [details.address],
                [details.id],
                [],
                [],
                []
            );
        }else if (type === "erc1155"){
            if (details.address === undefined || details.id === undefined || details.amount === undefined){
                return "Missing address, id or amount";
            }

            return withdrawal.withdraw(
                account,
                0,
                [],
                [],
                [],
                [],
                [details.address],
                [details.id],
                [details.amount]
            );
        }else{
            return "Error determining asset type"
        }
    }

    return (
        <>
            <PageHeading title="Withdraw assets" subTitle={locker !== null ? `From Locker #${locker.id}` :  "From Locker"} />
            <Container maxW='container.lg'>

                <Box bg='container' borderRadius='13px' p='15px' mt='30px'>
                    <Heading mb='20px' size='lg'>
                        Tokens
                    </Heading>

                    {
                        assets !== 'loading' ?
                            <TableContainer bg='container' borderRadius='13px'>
                                <Table variant='simple'>
                                    <Thead>
                                        <Tr>
                                            <Th>Name</Th>
                                            <Th>Type</Th>
                                            <Th isNumeric>Amount</Th>
                                            <Th isNumeric>Action</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {
                                            locker !== null ? assets.map((token) => {
                                                return <SimpleWithdrawERC20Item token={token} locker={locker} handler={withdrawAsset} />
                                            }) : ''
                                        }
                                    </Tbody>
                                </Table>
                            </TableContainer>
                            :
                            <Center h="100px" w="100%">
                                <Spinner />
                            </Center>
                    }
                </Box>

                <Box bg='container' borderRadius='13px' p='15px' mt='30px'>
                    <Heading mb='20px' size='lg'>
                        NFTs
                    </Heading>

                    {
                        nfts !== 'loading' ?
                            <SimpleGrid columns={[2, null, 6]} spacing="10px">
                                {
                                    locker !== null ? nfts.map((nft) => {
                                        return (
                                            <SimpleWithdrawNFTItem nft={nft} locker={locker} handler={withdrawAsset} />
                                        )
                                    }) : ''
                                }
                            </SimpleGrid>
                            :
                            <Center h="100px" w="100%">
                                <Spinner />
                            </Center>
                    }

                    {
                        nfts !== 'loading' && nfts.length === 0 ?
                            <Center mb="30px">
                                <Text variant='support'>
                                    No NFTs in this locker
                                </Text>
                            </Center>
                            : ""
                    }
                </Box>

            </Container>
        </>

    )
}