import {
    Button,
    Stack,
    useDisclosure,
    MenuItem, FormLabel, Input, FormHelperText, FormControl, Box, Text, SimpleGrid, Center, Spinner
} from "@chakra-ui/react";
import {
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
} from '@chakra-ui/react'

import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
} from '@chakra-ui/react'

import { GiCardJoker } from "react-icons/all";
import * as React from "react";
import { useEffect, useState } from "react";

import "flatpickr/dist/themes/dark.css";
import { ChainPicker } from "../ChainPicker/ChainPicker";
import { ILocker } from "../../helpers/Lockers";
import { BigNumber, utils } from "ethers";
import { NFT } from "../NFT/NFT";
import { ERC1155__factory, ERC165__factory, ERC721Metadata__factory } from "../../contracts";
import { TransactionButton } from "../TransactionButton/TransactionButton";
import { ContractReceipt } from "@ethersproject/contracts";
import { BetaMessage } from "../BetaMessage/BetaMessage";
import { useRpc } from "../../hooks/useRpc";
import { useAccount, useSigner } from "wagmi";
import { delay, ERC721Token, fetchNFTs } from "../../helpers/Assets";

export function LockNFTModal(props: { locker: ILocker | null }) {
    const { data: signer } = useSigner();
    const { data: account } = useAccount();

    const [nfts, updateNFTs] = useState<ERC721Token[] | 'loading'>('loading')

    const [ready, updateReady] = useState<boolean>();
    const { isOpen, onOpen, onClose } = useDisclosure()
    const provider = useRpc(props.locker?.chainId)

    const [nftAddress, updateNftAddress] = useState<string>("")
    const [nftId, updateNftId] = useState<string>("")
    const [nftAmount, updateNftAmount] = useState<string>("")

    const onStateChange = (newReady: boolean) => {
        updateReady(newReady)
    }

    const [nftType, updateNftType] = useState<'none' | 'erc721' | 'erc1155'>('none')
    useEffect(() => {
        if (utils.isAddress(nftAddress)) {
            const intro = ERC165__factory.connect(nftAddress, provider);
            intro.supportsInterface('0x5b5e139f').then(async (is721Metadata) => {
                if (is721Metadata) {
                    updateNftType('erc721')
                    return
                }

                const is1155Metadata = await intro.supportsInterface('0x0e89341c')
                if (is1155Metadata) {
                    updateNftType('erc1155')
                    return
                }

                const is1155 = await intro.supportsInterface('0xd9b67a26')
                if (is1155) {
                    updateNftType('erc1155')
                    return
                }

                // Idk what this even is, we assume ERC721
                updateNftType('erc721')
                return
            }, () => {
                updateNftType('none')
            });
        } else {
            updateNftType('none')
        }
    }, [nftAddress])

    useEffect(() => {
        // We have to delay this request so we don't get throttled by etherscan
        delay(2000).then(() => {
            if (isOpen && props.locker !== null && account?.address) {
                fetchNFTs(props.locker.chainId, account?.address).then((nfts) => {
                    updateNFTs(nfts)
                });
            }
        })

    }, [account, isOpen])

    const onDone = async (receipt: ContractReceipt) => {
        // Close the modal
        onClose()
        // Have the locker page reload the locker with fresh data
        if (props.locker && props.locker.refresh) {
            props.locker.refresh()
        } else {
            // Refresh the page
            window.location.reload();
        }
    }

    const pickNft = (selectedNft: ERC721Token) => {
        updateNftAddress(selectedNft.address);
        updateNftId(selectedNft.tokenId.toString());
    }

    const send = async () => {
        if (!nftAddress || !nftId || !props.locker || !signer || !account || !account.address) {
            return "Something went wrong, please refresh and try again"
        }

        if (await signer?.getChainId() !== props.locker.chainId) {
            return "User is connected to incorrect chain";
        }

        if (nftType === 'none') {
            return "Could not determine the NFT type";
        }

        if (nftType === 'erc721') {
            // Get the token instance and configure it
            const nftInstance = ERC721Metadata__factory.connect(nftAddress, signer)
            return nftInstance["safeTransferFrom(address,address,uint256)"](account.address, props.locker.address, nftId);
        } else {
            // Get the token instance and configure it
            const nftInstance = ERC1155__factory.connect(nftAddress, signer)
            return nftInstance.safeTransferFrom(account.address, props.locker.address, nftId, BigNumber.from(nftAmount), []);
        }
    }

    return (
        <>
            <MenuItem isDisabled={(props.locker === null || props.locker.unlocked)} onClick={onOpen} icon={<GiCardJoker size='20px' />}>
                Lock NFT
            </MenuItem>
            <Drawer
                isOpen={isOpen}
                placement='right'
                onClose={onClose}
                size='sm'
            >
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerCloseButton />
                    <DrawerHeader borderBottomWidth='1px'>
                        Lock NFT
                    </DrawerHeader>
                    {
                        // This way we aren't rendering these components if they are not visible
                        isOpen ? <>

                            <DrawerBody>
                                <Stack spacing='24px' paddingTop='20px'>
                                    <ChainPicker onStateChange={onStateChange} forceChain={props.locker ? props.locker.chainId : 0} />
                                </Stack>

                                {
                                    nfts === "loading" ?
                                        <Center h="100%" w="100%">
                                            <Spinner />
                                        </Center>
                                        :
                                        nfts.length === 0 ?
                                            <Center minHeight="100px">
                                                <Text variant='support' fontWeight='600'>
                                                    Could not find any NFTs for your wallet, please use advanced settings.
                                                </Text>
                                            </Center>
                                            :
                                            <SimpleGrid columns={2} spacing="10px" mt='20px'>
                                                {
                                                    nfts.map((nft) => {
                                                        return (
                                                            <Center onClick={() => pickNft(nft)}>
                                                                 <NFT key={`${nft.chainId}:${nft.address}:${nft.tokenId}`} token={nft} account={account?.address} overlay={false} />
                                                            </Center>
                                                           
                                                        )
                                                    })
                                                }
                                            </SimpleGrid>
                                }

                                {
                                    utils.isAddress(nftAddress) && nftId !== "" && props.locker ?
                                        <Box pl='10px' mt="20px" maxW="150px">
                                            <Text variant='support' mb='5px'>Preview</Text>
                                            <NFT
                                                size="200px"
                                                overlay={false}
                                                token={{
                                                    address: nftAddress,
                                                    chainId: props.locker.chainId,
                                                    tokenId: nftId,
                                                }} />
                                        </Box>
                                        : ""
                                }

                            </DrawerBody>
                            <Accordion allowToggle>
                                <AccordionItem>
                                    <h2>
                                        <AccordionButton>
                                            <Box flex='1' textAlign='left'>
                                                Advanced settings
                                            </Box>
                                            <AccordionIcon />
                                        </AccordionButton>
                                    </h2>
                                    <AccordionPanel pb={4}>

                                        <FormControl pl='10px' mt="20px">
                                            <FormLabel>
                                                Contract address of the NFT
                                            </FormLabel>
                                            <Input type='text' fontSize='sm' value={nftAddress} onChange={(newState) => updateNftAddress(newState.target.value)} isInvalid={nftAddress !== "" && !utils.isAddress(nftAddress)} />
                                            <FormHelperText>

                                            </FormHelperText>
                                        </FormControl>

                                        <FormControl pl='10px' mt="20px">
                                            <FormLabel>
                                                Token ID
                                            </FormLabel>
                                            <Input type='text' fontSize='sm' value={nftId} onChange={(newState) => updateNftId(newState.target.value)} />
                                            <FormHelperText>

                                            </FormHelperText>
                                        </FormControl>

                                        {
                                            nftType === 'erc1155' ?
                                                <FormControl pl='10px' mt="20px">
                                                    <FormLabel>
                                                        Token Amount
                                                    </FormLabel>
                                                    <Input type='number' fontSize='sm' value={nftAmount} onChange={(newState) => updateNftAmount(newState.target.value)} />
                                                    <FormHelperText>

                                                    </FormHelperText>
                                                </FormControl>
                                                : ""
                                        }

                                    </AccordionPanel>
                                </AccordionItem>
                            </Accordion>

                        </>
                            : ""
                    }

                    <BetaMessage />
                    <DrawerFooter borderTopWidth='1px'>

                        <Button variant='outline' mr={3} onClick={onClose}>
                            Cancel
                        </Button>

                        <TransactionButton
                            onClick={send}
                            confirmations={2}
                            warning={
                                // Only show the warning if the locker is not owned by the user
                                !props.locker || props.locker.owner !== account?.address ?
                                    <>
                                        <Text fontWeight={600}>You do not own this locker.</Text> Are you sure you want to lock tokens in a locker owned by someone else?
                                    </>
                                    :
                                    undefined
                            }
                            onDone={onDone}
                            disabled={!ready || !utils.isAddress(nftAddress) || nftId === ""}
                        >
                            Send transaction
                        </TransactionButton>
                    </DrawerFooter>
                </DrawerContent>
            </Drawer>
        </>
    )
}