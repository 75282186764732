import {Container, Flex, Spacer} from "@chakra-ui/react";
import {LockerList} from "../../components/LockerList/LockerList";
import * as React from "react";
import {NewLockerModal} from "../../components/NewLocker/NewLockerModal";
import {useEthers} from "@usedapp/core";
import {useFactory} from "../../hooks/useFactory";
import {fetchNewestLockers, ILocker, LockerReference} from "../../helpers/Lockers";
import {useEffect, useState} from "react";
import {Helmet} from "react-helmet";
import {Ethereum} from "../../config/chains/Ethereum";
import {ChainSelector} from "../../components/ChainSelector/ChainSelector";
import {getSelectedChains} from "../../helpers/ChainHelper";
import debounce from 'lodash.debounce'
import {PageHeading} from "../../partials/PageHeading";

export function NewLockers(){
    const { chainId } = useEthers()
    const lockerFactory = useFactory(Ethereum.chainId)
    const [chainsToUse, updateChainsToUse] = useState<number[]>(getSelectedChains());
    const [lockerReferences, updateLockerReferences] = useState<LockerReference[] | ILocker[] | undefined>(undefined)

    const debouncedRefresh = React.useRef(
        debounce(() => {
            updateChainsToUse(getSelectedChains());
        }, 1500)
    ).current;

    const refreshChainsToUse = function () {
        updateLockerReferences(undefined);
        debouncedRefresh();
    }

    useEffect(() => {
        if (lockerFactory){
            fetchNewestLockers(chainsToUse)
                .then((lockers) => {updateLockerReferences(lockers)})
        }
    }, [chainId, chainsToUse])

    return (
        <>
            <PageHeading
                title='Newest lockers'
            />

            <Container maxW='container.lg' mt="20px">
                <Helmet>
                    <title>Newest lockers</title>
                </Helmet>

                <Flex mb={3}>
                    <ChainSelector onChange={refreshChainsToUse} />
                    <Spacer />
                    <NewLockerModal />
                </Flex>
                <LockerList lockers={lockerReferences} />
            </Container>
        </>
    )
}