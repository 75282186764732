import {ChainConfig} from "../config/config";

export function getMarketplace(chainId: number){
    const marketplaces = ChainConfig[chainId].marketplaces

    if (marketplaces === undefined || marketplaces.length === 0){
        return undefined
    }

    return marketplaces[0]
}