import {
    Text,
    Button,
    Stack,
    useDisclosure,
    MenuItem
} from "@chakra-ui/react";
import {
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
} from '@chakra-ui/react'
import {GiTwoCoins} from "react-icons/all";
import * as React from "react";
import {useEffect, useState} from "react";

import "flatpickr/dist/themes/dark.css";
import {useEthers} from "@usedapp/core";
import {ChainPicker} from "../ChainPicker/ChainPicker";
import {ILocker} from "../../helpers/Lockers";
import {TokenInput} from "../TokenInput/TokenInput";
import {ethers} from "ethers";
import {ContractReceipt} from "@ethersproject/contracts";
import {ERC20Token} from "../../helpers/Assets";
import {ERC20__factory} from "../../contracts";
import {TransactionButton} from "../TransactionButton/TransactionButton";
import {BetaMessage} from "../BetaMessage/BetaMessage";
import {useAccount, useSigner} from "wagmi";

export function LockTokensModal(props: {locker: ILocker | null}) {
    const { data: signer } = useSigner();
    const { data: account } = useAccount();

    const [ready, updateReady] = useState<boolean>();
    const [chainState, updateChainState] = useState<boolean>();
    const { isOpen, onOpen, onClose } = useDisclosure()

    const [tokenAmount, updateTokenAmount] = useState<{token: ERC20Token, amount: ethers.BigNumber}>()

    useEffect(() => {
            // Verify the chain is correct, token is set and amount is not 0
            updateReady((chainState && tokenAmount !== undefined && !tokenAmount.amount.isZero()))
        },
        [chainState, tokenAmount?.token.address, tokenAmount?.amount]
    )

    const onTokenAmountChange = (token: ERC20Token, amount: ethers.BigNumber) => {
        updateTokenAmount({
            token: token,
            amount: amount
        })
    }

    const onStateChange = (newReady: boolean) => {
        updateChainState(newReady)
    }

    const send = async () => {
        if (!tokenAmount || !account || !signer || !props.locker){
            return "Something went wrong, please refresh and try again"
        }

        // This case should never happen, but we are handling it just in-case
        // After this the token, locker and user should all be on the same chain
        if (tokenAmount.token.chainId !== props.locker.chainId || await signer.getChainId() !== props.locker.chainId){
            return "You are not connected to the correct chain"
        }

        // Get the token instance and configure it
        const tokenInstance = ERC20__factory.connect(tokenAmount.token.address, signer)
        return tokenInstance.transfer(
            props.locker.address,
            tokenAmount.amount
        )
    }

    const onDone = async (receipt: ContractReceipt) => {
        // Close the modal
        onClose()
        // Have the locker page reload the locker with fresh data
        if (props.locker && props.locker.refresh){
            props.locker.refresh()
        }else{
            // Refresh the page
            window.location.reload();
        }
    }

    return (
        <>
            <MenuItem isDisabled={(props.locker  === null || props.locker.unlocked)} onClick={onOpen} icon={<GiTwoCoins size='20px' />}>
                Lock Tokens
            </MenuItem>
            <Drawer
                isOpen={isOpen}
                placement='right'
                onClose={onClose}
                size='sm'
            >
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerCloseButton />
                    <DrawerHeader borderBottomWidth='1px'>
                        Lock tokens
                    </DrawerHeader>
                    {
                        // This way we aren't rendering these components if they are not visible
                        isOpen ?
                            <DrawerBody>
                                <Stack spacing='24px' paddingTop='20px'>
                                    <ChainPicker onStateChange={onStateChange} forceChain={props.locker ? props.locker.chainId : 0} />
                                    {
                                        account?.address && props.locker ? <TokenInput chainId={props.locker?.chainId} account={account.address} onChange={onTokenAmountChange} /> : 0
                                    }
                                </Stack>
                            </DrawerBody>
                            : ""
                    }

                    <BetaMessage />
                    <DrawerFooter borderTopWidth='1px'>
                        <Button variant='outline' mr={3} onClick={onClose}>
                            Cancel
                        </Button>
                        <TransactionButton
                            onClick={send}
                            confirmations={2}
                            warning={
                                // Only show the warning if the locker is not owned by the user
                                !props.locker || props.locker.owner !== account?.address ?
                                <>
                                    <Text fontWeight={600}>You do not own this locker.</Text> Are you sure you want to lock tokens in a locker owned by someone else?
                                </>
                                    :
                                undefined
                            }
                            onDone={onDone}
                            disabled={!ready}
                        >
                            Send transaction
                        </TransactionButton>
                    </DrawerFooter>
                </DrawerContent>
            </Drawer>
        </>
    )
}