import {useEthers} from "@usedapp/core";
import {LockerFactory__factory} from "../contracts";
import {useRpc} from "./useRpc";
import {isChainSupported} from "../helpers/ChainHelper";
import {getFactory} from "../helpers/DeploymentsHelper";

export const useFactory = function (overrideChainId?: number){
    const { chainId } = useEthers()
    const provider = useRpc(overrideChainId)
    const chainIdToUse = overrideChainId ? overrideChainId : chainId ? chainId : 1;

    if (isChainSupported(chainIdToUse)){
        return getFactory(chainIdToUse, provider);
    }
}