import {Center, Container, Flex, Heading, Spacer} from "@chakra-ui/react";
import {LockerList} from "../../components/LockerList/LockerList";
import * as React from "react";
import {NewLockerModal} from "../../components/NewLocker/NewLockerModal";
import {fetchUserLockers, ILocker} from "../../helpers/Lockers";
import {useEffect, useState} from "react";
import {Helmet} from "react-helmet";
import {useAccount} from "wagmi";
import {getSelectedChains} from "../../helpers/ChainHelper";
import debounce from "lodash.debounce";
import {ChainSelector} from "../../components/ChainSelector/ChainSelector";
import {PageHeading} from "../../partials/PageHeading";

export function OwnedLockers(){
    const { data: account } = useAccount();
    const [chainsToUse, updateChainsToUse] = useState<number[]>(getSelectedChains());
    const [lockers, updateLockers] = useState<ILocker[] | undefined>(undefined);

    const debouncedRefresh = React.useRef(
        debounce(() => {
            updateChainsToUse(getSelectedChains());
        }, 1500)
    ).current;

    const refreshChainsToUse = function () {
        updateLockers(undefined);
        debouncedRefresh();
    }

    useEffect(() => {
        if (account?.address){
            updateLockers(undefined)

            fetchUserLockers(account.address, chainsToUse)
                .then((lockers) => {
                    updateLockers(lockers)
                })
        }else if (account === null){
            updateLockers(undefined)
        }
    }, [account, account?.address, chainsToUse])

    return (
        <>
            <PageHeading
                title='Your lockers'
            />

            <Container maxW='container.lg' mt="20px">
                <Helmet>
                    <title>My lockers</title>
                </Helmet>

                {
                    account ?
                        <>
                            <Flex mb={3}>
                                <ChainSelector onChange={refreshChainsToUse} />
                                <Spacer />
                                <NewLockerModal />
                            </Flex>
                            <LockerList lockers={lockers} />
                        </>
                    :
                        <Center>
                            <Heading fontSize='lg'>
                                You need to connect your wallet to be able to view your lockers
                            </Heading>
                        </Center>
                }
            </Container>
        </>
    )
}