import {
    Badge,
    Box, Center, Divider,
    HStack,
    Image, SimpleGrid,
    Skeleton, Spinner, Tab,
    Table,
    TableCaption, TabList, TabPanel, TabPanels, Tabs,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    Wrap, WrapItem
} from "@chakra-ui/react";
import * as React from "react";
import {ERC20Token, ERC721Token} from "../../helpers/Assets";
import {AssetsItem} from "./AssetsItem";
import {NFT} from "../NFT/NFT";
import {AssetsAttribution} from "./AssetsAttribution";

const selectedTabStyle = {
    color: '#EEE',
    border: '1px',
    borderColor: 'inherit',
    borderBottom: 'none',
    borderTopRadius: '5px',
}

export function Assets(props: {assets: ERC20Token[] | 'loading', nfts: ERC721Token[] | 'loading', address: string, chainId: number}){
    return (
        <Tabs size='md' variant='enclosed' borderRadius={5} borderWidth="1px" bg='container'>
            <TabList color='fontAccent'>
                <Tab fontSize='sm' fontWeight='600'  _selected={selectedTabStyle} _focus={{outline: 'none'}}>
                    Tokens
                    { props.assets !== 'loading' ?
                        <Badge ml="8px" colorScheme='green'>{props.assets.length}</Badge>
                        : <Badge ml="8px" mt="4px" colorScheme='green'><Spinner mt="4px" size='xs' /></Badge>
                    }
                </Tab>
                <Tab fontSize='sm' fontWeight='600' _selected={selectedTabStyle} _focus={{outline: 'none'}}>
                    NFTs
                    { props.nfts !== 'loading' ?
                        <Badge ml="8px" colorScheme='green'>{props.nfts.length}</Badge>
                        : <Badge ml="8px" colorScheme='green'><Spinner mt="4px" size='xs' /></Badge>
                    }
                </Tab>
            </TabList>
            <TabPanels h='100%' minHeight='100px'>
                <TabPanel h='100%'>
                    {
                        props.assets !== 'loading' && props.assets.length === 0 ?
                            <Center minHeight="100px">
                                <Text variant='support' fontWeight='600'>
                                    No tokens have been locked (yet)
                                </Text>
                            </Center>
                        :
                            <Table variant='simple' size='sm'>
                                {
                                    props.assets !== 'loading' && props.assets.length === 0 ?
                                        <TableCaption mt="0px">
                                            <Text variant='support' fontWeight='600'>
                                                Locker has no assets
                                            </Text>
                                        </TableCaption> : ""
                                }
                                <Thead>
                                    <Tr>
                                        <Th>
                                            Asset
                                        </Th>
                                        <Th>
                                            Amount
                                        </Th>
                                        <Th>

                                        </Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {
                                        props.assets !== "loading" ? props.assets.map((token) => {
                                            return (
                                                <AssetsItem item={token} address={props.address} />
                                            )
                                        }) : <Tr>
                                            <Td><Skeleton h="20px" /></Td>
                                            <Td><Skeleton h="20px" /></Td>
                                        </Tr>
                                    }
                                </Tbody>
                            </Table>
                    }
                </TabPanel>
                <TabPanel h='100%'>
                    {
                        props.nfts === "loading" ?
                            <Center h="100%" w="100%">
                                <Spinner />
                            </Center>
                            :
                            props.nfts.length === 0 ?
                                <Center minHeight="100px">
                                    <Text variant='support' fontWeight='600'>
                                        No NFTs have been locked (yet)
                                    </Text>
                                </Center>
                                :
                            <SimpleGrid columns={[2, null, 3]} spacing="10px">
                                {
                                    props.nfts.map((nft) => {
                                        return (
                                            <NFT key={`${nft.chainId}:${nft.address}:${nft.tokenId}`} token={nft} account={props.address} />
                                        )
                                    })
                                }
                            </SimpleGrid>
                    }
                </TabPanel>
            </TabPanels>
        </Tabs>
        // <Box width="100%">
        //     <Box width="100%" >

        //     </Box>
        //
        //     <Box>
        //         <Text>NFTs</Text>
        //         <Divider />
        //
        //         <SimpleGrid columns={3} spacing="10px">
        //
        //             <NFT token={{
        //                 address: "0xC36442b4a4522E871399CD717aBDD847Ab11FE88",
        //                 chainId: 4,
        //                 tokenId: 14660,
        //                 name: "Uniswap V3 Positions NFT-V1",
        //                 symbol: "UNI-V3-POS",
        //                 decimals: 0,
        //             }} />
        //
        //             {/*<NFT token={{*/}
        //             {/*    address: "0xC36442b4a4522E871399CD717aBDD847Ab11FE88",*/}
        //             {/*    chainId: 4,*/}
        //             {/*    tokenId: 14660,*/}
        //             {/*    name: "Uniswap V3 Positions NFT-V1",*/}
        //             {/*    symbol: "UNI-V3-POS",*/}
        //             {/*    decimals: 0,*/}
        //             {/*}} />*/}
        //
        //             {/*<NFT token={{*/}
        //             {/*    address: "0xC36442b4a4522E871399CD717aBDD847Ab11FE88",*/}
        //             {/*    chainId: 4,*/}
        //             {/*    tokenId: 14660,*/}
        //             {/*    name: "Uniswap V3 Positions NFT-V1",*/}
        //             {/*    symbol: "UNI-V3-POS",*/}
        //             {/*    decimals: 0,*/}
        //             {/*}} />*/}
        //
        //         </SimpleGrid>
        //     </Box>
        //
        //
        //     <Text ml="5px" mt="2px" color="whiteAlpha.400" fontSize='xs'>Asset data provided by Etherscan</Text>
        // </Box>
    )
}