import {
    Button,
    Stack,
    useDisclosure,
    MenuItem, FormLabel, Box, FormHelperText, FormControl
} from "@chakra-ui/react";
import {
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
} from '@chakra-ui/react'
import {RiTimerFlashLine} from "react-icons/all";
import * as React from "react";
import {useEffect, useState} from "react";

import "flatpickr/dist/themes/dark.css";
import {ChainPicker} from "../ChainPicker/ChainPicker";
import {ILocker} from "../../helpers/Lockers";
import {ContractReceipt} from "@ethersproject/contracts";
import Flatpickr from "react-flatpickr";
import {useFactory} from "../../hooks/useFactory";
import {getUnixTime} from "date-fns";
import {TransactionButton} from "../TransactionButton/TransactionButton";
import {BetaMessage} from "../BetaMessage/BetaMessage";
import {useAccount, useSigner} from "wagmi";

export function ExtendLockModal(props: {locker: ILocker | null}) {
    const { data: signer } = useSigner();
    const { data: account } = useAccount();

    const factory = useFactory(props.locker?.chainId)
    const [ready, updateReady] = useState<boolean>();
    const [chainState, updateChainState] = useState<boolean>();
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [unlockDate, updateUnlockDate] = useState<Date>();

    useEffect(() => {
            // Verify the chain is correct, token is set and amount is not 0
            updateReady((chainState && unlockDate !== undefined && factory !== undefined))
        },
        [chainState, unlockDate, factory]
    )

    const onStateChange = (newReady: boolean) => {
        updateChainState(newReady)
    }

    const send = async () => {
        if (!signer || !account || !props.locker || !factory || unlockDate === undefined){
            return "Something went wrong, please refresh and try again"
        }

        // This case should never happen, but we are handling it just in-case
        // After this the token, locker and user should all be on the same chain
        if (await signer.getChainId() !== props.locker.chainId){
            return "You are not connected to the correct chain"
        }

        return factory.connect(signer).extend(
            props.locker.id,
            getUnixTime(unlockDate)
        )
    }

    const onDone = async (receipt: ContractReceipt) => {
        // Close the modal
        onClose()
        // Have the locker page reload the locker with fresh data
        if (props.locker && props.locker.refresh){
            props.locker.refresh()
        }else{
            // Refresh the page
            window.location.reload();
        }
    }

    return (
        <>
            <MenuItem isDisabled={(props.locker === null || props.locker.unlocked || props.locker.owner !== account)} onClick={onOpen} icon={<RiTimerFlashLine size='20px' />}>
                Extend lock
            </MenuItem>
            <Drawer
                isOpen={isOpen}
                placement='right'
                onClose={onClose}
                size='sm'
            >
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerCloseButton />
                    <DrawerHeader borderBottomWidth='1px'>
                        Extend Locker
                    </DrawerHeader>
                    {
                        // This way we aren't rendering these components if they are not visible
                        isOpen ?
                            <DrawerBody>
                                <Stack spacing='24px' paddingTop='20px'>
                                    <ChainPicker onStateChange={onStateChange} forceChain={props.locker ? props.locker.chainId : 0} />
                                    {
                                        account && props.locker ?
                                            <FormControl pl='10px' m='15px 0px 0px 0px'>
                                                <FormLabel>
                                                    New unlock date
                                                </FormLabel>
                                                <Box sx={{'.datepicker': {
                                                        border: '1px solid',
                                                        borderColor: 'inherit',
                                                        bg: 'inherit',
                                                        width: '100%',
                                                        height: '2.5rem',
                                                        borderRadius: '0.375rem',
                                                        px: '16px',
                                                    }}}>
                                                    <Flatpickr
                                                        value={unlockDate}
                                                        options={{
                                                            minDate: Date.now() < props.locker.unlockAt.getTime() ? props.locker.unlockAt : Date.now()
                                                        }}
                                                        onChange={([newUnlockDate]) => {
                                                            updateUnlockDate(newUnlockDate);
                                                        }}
                                                        className='datepicker'
                                                    />
                                                </Box>
                                                <FormHelperText>
                                                    After this date the locker becomes unlockable by the owner.
                                                </FormHelperText>
                                            </FormControl>
                                            : 0
                                    }
                                </Stack>
                            </DrawerBody>
                            : ""
                    }

                    <BetaMessage />
                    <DrawerFooter borderTopWidth='1px'>
                        <Button variant='outline' mr={3} onClick={onClose}>
                            Cancel
                        </Button>
                        <TransactionButton
                            onClick={send}
                            onDone={onDone}
                            confirmations={0}
                            disabled={!ready}
                        >
                            Extend Lock
                        </TransactionButton>
                    </DrawerFooter>
                </DrawerContent>
            </Drawer>
        </>
    )
}