import {Container, Flex, Box, Heading, VStack} from "@chakra-ui/react";
import * as React from "react";
import { ConnectButton } from '@rainbow-me/rainbowkit';

interface PageHeadingProps {
    startColor?: string
    endColor?: string
    title?: string
    subTitle?: string
    children?: any
}

export function PageHeading(props: PageHeadingProps){
    const startColor = props.startColor ? props.startColor : "rgba(67,233,123,0.4)"
    const endColor = props.endColor ? props.endColor : "rgba(56,249,215,0.4)"

    return (
        <Box mb='20px' bgGradient={`linear(to-br, ${startColor}, ${endColor})`} alignItems='center' height={{base: "200px", md: '150px'}}>
            <Box position='absolute' right='20px' top='20px'>
                <ConnectButton chainStatus={"none"} showBalance={false}/>
            </Box>
            <Container maxW='container.lg' h='100%' pt={{base: '50px', md: '10px'}}>
                <Flex alignItems='center' p='lg' h='100%' fontFamily={"IBM Plex Mono"}>
                    {
                        props.children ?
                            props.children
                        :
                            <VStack align='left'>
                                <Heading fontWeight="700">
                                    {props.title}
                                </Heading>

                                {
                                    props.subTitle ?
                                        <Heading color='#1e262f' fontWeight="300" fontSize="md">
                                            {props.subTitle}
                                        </Heading>
                                        : ""
                                }
                            </VStack>
                    }
                </Flex>
            </Container>
        </Box>
    )
}