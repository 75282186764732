import {ERC20Token} from "../../helpers/Assets";
import {HStack, Link, Skeleton, Td, Text, Tr} from "@chakra-ui/react";
import * as React from "react";
import {useBalance} from "../../hooks/useBalance";
import {utils} from "ethers";
import {TokenLogo} from "../TokenLogo/TokenLogo";
import {GoLinkExternal} from "react-icons/all";
import {useChainMeta} from "../../hooks/useChainMeta";

export function AssetsItem(props: {item: ERC20Token, address: string}){
    const balance = useBalance(props.item.chainId, props.item.address, props.address)
    const chainMeta = useChainMeta(props.item.chainId)

    return (
        <Tr>
            <Td>
                <HStack>
                    <TokenLogo token={props.item} h={'15px'} w={'15px'} />
                    <Text ml="5px">{props.item.name}</Text>
                </HStack>
            </Td>
            <Td>
                <Skeleton isLoaded={balance !== null}>
                    {balance ? utils.formatUnits(balance, props.item.decimals) : "0"}
                </Skeleton>
            </Td>
            <Td>
                <Link href={chainMeta ? chainMeta.getExplorerAddressLink(props.item.address) : ""} target='_blank'>
                    <GoLinkExternal />
                </Link>
            </Td>
        </Tr>
    )
}