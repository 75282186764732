import {Chain} from "@usedapp/core/src/constants/index";

export const MilkomedaCardano: Chain = {
    chainId: 2001,
    chainName: 'Milkomeda ADA',
    isTestChain: false,
    isLocalChain: false,
    multicallAddress: '0xe520aF265c5488104D9f63091b80F0a9feBF5070',
    multicall2Address: '0x61EEE5a6c13c358101487f3b7c7Dd9863590C350',
    getExplorerAddressLink: (address: string) => `https://explorer-mainnet-cardano-evm.c1.milkomeda.com/address/${address}`,
    getExplorerTransactionLink: (transactionHash: string) => `https://explorer-mainnet-cardano-evm.c1.milkomeda.com/tx/${transactionHash}`,
}